@import 'assets/variables.scss';
.all-chats-name {
  height: 100%;
  max-height: calc(100vh - 200px);
  width: 20%;
  max-width: 400px;
  min-width: 330px;
  border-right: 1px solid $border-colr;
}

.message-container {
  width: 80%;
  // height: 85vh;
  height: 100%;
}

.recepient-container {
  // height: 85vh;
  max-height: calc(100vh - 200px);
  height: 100%;
  width: 20%;
  max-width: 400px;
  min-width: 330px;
  border-left: 1px solid $border-colr;

  &.hidden {
    display: none;
  }

  @media screen and (max-width: 1366px) {
    max-width: 300px;
    min-width: 250px;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.height-chat-box {
  height: calc(100% - 250px);
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 30px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none !important;
  }
}
