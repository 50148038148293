* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  background-color: #e5e5e5 !important;
}

.b-transparent {
  background-color: transparent !important;
}

.scroll-w-0::-webkit-scrollbar {
  width: 0;
}

.ant-form {
  color: #000;
}

.display-column {
  display: flex;
  flex-direction: column;
}

.activeMenu {
  background-color: #2ba7ca !important;
  color: #ffff !important;
}

.text-pink {
  color: #e8769f;
}

.text-green {
  color: #a6ca16;
}

.text-primary {
  color: #2ba7ca !important;
}

.text-white {
  color: #fff;
}

.text-secondary {
  color: #9ca5b0 !important;
}

.text-danger {
  color: #e8769f !important;
}

.bg-text-secondary {
  background-color: #9ca5b0 !important;
}

.actionButton {
  width: 220px;
  height: 48px;
  background-color: #2ba7ca !important;
}

.large_add_button {
  background: #eeeff1;
  border: 1px solid #eeeff1;
  border-radius: 8px;
  max-width: 327px;
  width: 100%;
  height: 52px;
}

.credit_card_button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 66px 12px 56px;
  gap: 13px;
  border: 1px solid #2ba7ca;
  border-radius: 8px;
  width: 285px;
  height: 48px;
}

.register_child_button {
  background: #2ba7ca;
  width: 165px;
  height: 36px;
  color: #fff;
  padding: 6px 32px;
  border-radius: 20px;
}

.outlined-button {
  border-color: #2ba7ca;
  color: #2ba7ca;
  justify-content: center;
  align-self: center;
}

.button-lg {
  border-radius: 20px;
  text-align: center;
  width: 200px;
  color: #fff;
}

.radioButton {
  background-color: #ebedf1 !important;
  color: #000 !important;
  border-radius: 8px !important;
  border: none !important;
  margin: 2px;
  text-align: center;
  padding: 5px;
}

.radioButtonSelected {
  background-color: #000229 !important;
  color: #fff !important;
  border-radius: 8px !important;
}

.container {
  width: 100% !important;
  height: calc(100vh - 74px) !important;
}

.ant-layout {
  background-color: #fff !important;
  min-height: 100%;
}

.primary {
  background-color: #2ba7ca;
  color: white !important;
}

.secondary {
  background-color: #ebedf1;
  color: black;
}

.bg-primary {
  background-color: #2ba7ca;
  color: white !important;
}

.bg-secondary {
  background-color: #eeeff1;
  color: black;
}

.o-icon-btn {
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  width: 30px;
  text-align: center;
  height: 30px;
}

.ant-btn {
  border-color: #ebedf1;
}

.text-gray-color {
  color: #9ca5b0 !important;
}

.bg-grey {
  background-color: #e5e5e5 !important;
}

.bg-white {
  background-color: #ffff;
}

.bg-light-gray {
  background-color: #f5f6f8 !important;
}

.bg-red {
  background-color: #ff6961 !important;
}

.bg-dark {
  background-color: #000 !important;
}

h6 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 20px;
}

h5 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 14px;
}

h4 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 16px;
}

h3 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 18px;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 22px;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 24px;
}

.profile_title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
}

.font14 {
  font-size: 14px !important;
  font-family: 'Montserrat', sans-serif;
}

.font15 {
  font-size: 15px !important;
  font-family: 'Montserrat', sans-serif;
}

.font16 {
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif;
}

.font17 {
  font-size: 17px !important;
  font-family: 'Montserrat', sans-serif;
}

.font13 {
  font-size: 13px !important;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}

.font12 {
  font-size: 12px !important;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}

.font10 {
  font-size: 10px !important;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}

.subtitle {
  color: #9ca5b0;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.subtitle2 {
  color: #9ca5b0;
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.subtitle3 {
  color: #9ca5b0;
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.b1 {
  font-weight: 600;
}

.b2 {
  font-weight: 500;
}

.b3 {
  font-weight: 400;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.white-box {
  background-color: #fff;
  width: 100%;
}

.padding-30 {
  padding: 30px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-10 {
  padding-top: 10px;
}

.Montserrat {
  font-family:
    'Montserrat',
    sans-serif I !important;
}

.b-solid-1 {
  border: 1px solid;
}

.bb-solid-1 {
  border-bottom: 1px solid;
}

.b-color-gray {
  border-color: #eeeff1;
}

.b-0 {
  border: none !important;
}

.br-10 {
  border-radius: 10px !important;
}

.br-8 {
  border-radius: 8px !important;
}

.br-5 {
  border-radius: 5px !important;
}

.br-12 {
  border-radius: 12px !important;
}

.br-20 {
  border-radius: 20px !important;
}

.br-50 {
  border-radius: 50px !important;
}

.box-shadow {
  box-shadow: none !important;
}

.cursor_pointer {
  cursor: pointer;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}
.ml-auto {
  margin-left: auto !important;
}
.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.object-fit-cover {
  object-fit: cover;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::after,
::before {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

:focus-visible {
  outline: none;
}

body {
  background-color: #ffffff;
}

.site-layout-content {
  display: flex;
}

.col {
  padding: 0 15px;
}

.Aesthetic-light {
  background-color: #f1fcff;
}

.Aesthetic-dark {
  background-color: #eaf1ff;
}

.Lavender-light {
  background-color: #fff1f3;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-0 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}

b,
strong {
  font-weight: bolder;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

label {
  display: inline-block;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

span {
  display: block;
}

.display-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subtitle {
  color: #9ca5b0;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.ml-20 {
  margin-left: 20px;
}

.ml-100 {
  margin-left: 100px !important;
}

.text-black {
  color: #000229;
}

.text-color-black {
  color: #000 !important;
}

.text-color-blue {
  color: #2ba7ca !important;
}

.ant-btn-primary {
  border-color: #2ba7ca;
  background: #2ba7ca;
}

.text-color-red {
  color: #ff6961 !important;
}

.bg-green {
  background-color: #a6ca16;
}

.green-btn {
  background-color: #8cc73f;
  border: 1px solid #8cc73f;
  border-radius: 6px;
  color: #fff;
  padding: 8px 30px;
  height: 100%;
}

.bg-danger {
  background-color: #e8769f !important;
  color: #fff;
}

.primary {
  background-color: #2ba7ca;
  color: white;
}

.bg-tran {
  background: transparent !important;
}

.primary-transparent {
  background-color: transparent !important;
  color: #2ba7ca !important;
  border-color: #2ba7ca !important;
  font-size: 15px;
  line-height: 23px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 10px;
}

.venue-primary-transparent {
  background-color: #ffffff !important;
  color: #2ba7ca !important;
  border-color: #2ba7ca !important;
  width: 520px;
  height: 51px;
  border-radius: 8px;
}

.gray-border {
  border: 1px solid #ebedf1;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #000000;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #000229;
}

h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #000000;
}

h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #000000;
}

h5 {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #000000;
}

h5,
h4,
h3,
h2,
h1 {
  margin-bottom: 0px !important;
}

.f-italic {
  font-style: italic !important;
}

.f-normal {
  font-style: normal !important;
}

.f-light {
  font-weight: 300 !important;
}

.f-lighter {
  font-weight: lighter !important;
}

.f-normal {
  font-weight: 400 !important;
}

.f-regular {
  font-weight: 500;
}

.f-bold {
  font-weight: 700 !important;
}

.f-semibold {
  font-weight: 600 !important;
}

.f-bolder {
  font-weight: bolder !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.p-20 {
  padding: 20px;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-38 {
  margin-bottom: 38px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.me-auto {
  margin-right: auto !important;
}

.ms-auto {
  margin-left: auto !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-15 {
  gap: 15px !important;
}

.gap-10 {
  gap: 10px !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-w-140 {
  min-width: 140px !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.max-w-140 {
  max-width: 140px !important;
}

.w-325 {
  width: 325px !important;
}

.btn-h-50 {
  height: 50px !important;
}

.w-155 {
  width: 155px !important;
}

.h-25 {
  height: 25% !important;
}

.hl-50 {
  line-height: 50px !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.ant-switch-checked {
  background-color: #a6ca16;
}

.display-column {
  display: flex;
  flex-direction: column;
}

.actionButton {
  width: 220px;
  height: 48px;
  background-color: #2ba7ca !important;
  border-radius: 12px !important;
}

.alert_delete_button {
  width: 169px;
  height: 52px;
  border-radius: 8px;
  background-color: #ff6961;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}
.alert_delete_button-blue {
  width: 169px;
  height: 52px;
  border-radius: 8px;
  background-color: #2ba7ca;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.alert_action_button {
  width: 169px;
  height: 52px;
  border-radius: 8px;
  background-color: #2ba7ca;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.radioButton {
  background-color: #ebedf1 !important;
  color: #000 !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 9px 26px !important;
  height: 100%;
}

.radioButtonSelected {
  padding: 9px 26px;
  height: 100%;
  border: none !important;
  background-color: #000229 !important;
  color: #fff !important;
}

.bg-white {
  background-color: #ffff !important;
}

.bg-blue {
  background-color: #2ba7ca !important;
}

.padding-40 {
  padding: 40px;
}

.Montserrat {
  font-family: 'Montserrat', sans-serif !important;
}

.bottom_gray_border {
  border-bottom: 1px solid #ebedf1;
}

.page-header {
  padding-left: 40px;
  margin-top: 20px;
  padding-right: 40px;
}

.page-content {
  padding: 0 40px;
  margin-top: 30px;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-26 {
  margin-bottom: 26px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}
.m-10 {
  margin: 10px;
}
.p-30 {
  padding: 30px;
}

.pr {
  padding-right: 40px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-7 {
  padding-left: 7px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 16px;
}

.profile_container {
  height: 100% !important;
  width: 100% !important;
  padding: 30px 60px;
  background-color: #fff !important;
}

@media screen and (max-width: 1440px) {
  .profile_container {
    padding: 30px 30px;
  }
}

.profile_header {
  padding: 40px !important;
  background-color: #fff !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.space-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select-selection-placeholder {
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #bcbec0 !important;
}

.modal_container {
  min-height: 450px !important;
}

.container-event {
  width: 100% !important;
  height: auto !important;
}

.ant-table-thead > tr > th,
.ant-table tfoot > tr > th {
  padding: 13px 13px;
}

.ant-table-tbody > tr > td,
.ant-table tfoot > tr > td {
  padding: 8px 13px;
}

.orgnz-sect-heading {
  font-size: 14px;
}

.ant-btn {
  min-height: 36px;
}

.btn-item {
  min-height: 38px;
  border: 1px solid #ebedf1;
  border-radius: 10px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  line-height: 21px;
}

.big-btn-blue {
  width: 327px;
  height: 52px;
  border-radius: 10px;
  background-color: #2ba7ca;
  color: #ffffff;
  justify-content: center;
}

.big-btn-gray {
  width: 327px;
  height: 52px;
  border-radius: 10px;
  background-color: #ebedf1;
  border: 1px solid #eeeff1;
  justify-content: center;
}

.gry-btn {
  font-weight: 500;
  font-size: 15px;
  background-color: #ebedf1;
}

.primary-btn {
  font-weight: 500;
  font-size: 15px;
  background-color: #2ba7ca;
  border: 1px solid transparent;
  color: #fff;
}

.primary-btn:hover {
  background-color: transparent;
  border-color: #2ba7ca;
  color: #2ba7ca;
}
.flex {
  display: flex;
}
.w-30 {
  width: 30px;
}
.align-center {
  align-items: center;
}
.colored_header_table .ant-table-thead > tr > th {
  background: #ebedf1 !important;
}
