.ant-dropdown-menu-item-only-child:hover,
.ant-dropdown-menu-item:hover {
  background-color: transparent !important;
}
.dropdwn {
  padding: 10px 14px;
}
.dropdwn label {
  margin-bottom: 12px;
  margin-right: 0;
}
.dropdwn span {
  font-weight: 500;
  font-size: 14px;
}
.dropdwn .ant-checkbox-wrapper:hover {
  background-color: rgba(43, 167, 202, 0.15) !important;
  padding: 10px;
  border-radius: 8px;
}
.clr-btn {
  background-color: transparent;
  border: none;
  font-weight: 500;
  color: rgba(147, 149, 152, 1);
  cursor: pointer;
}
.aply-btn {
  background-color: rgba(43, 167, 202, 1);
  color: #fff;
  padding: 8px 24px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}
.sm-inp {
  border: 1px solid rgba(235, 237, 241, 1);
  border-radius: 8px;
  height: 35px;
  width: 85px;
  padding: 8px;
}
.inp-n {
  border: 1px solid rgba(235, 237, 241, 1);
  border-radius: 8px;
  height: 35px;
  width: 85px;
}
.sm-inp::-webkit-outer-spin-button,
.sm-inp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loc {
  color: rgba(43, 167, 202, 1);
  font-weight: 500;
}
.loc:hover {
  text-decoration: underline;
}
.event-detail .date {
  display: flex;
  align-items: center;
  padding: 8px;
  padding-right: 11px;
  background-color: #ebedf1;
  border-radius: 8px;
  margin-left: 12px;
  height: 36px;
}

.event-detail .date span {
  font-size: 12px;
  color: #000000;
  font-weight: 500;
  padding-left: 4px;
}

.event-detail .date span .bold {
  font-size: 14px;
  font-weight: 600;
  margin-left: 7px;
  font-family: 'Montserrat', sans-serif;
}

.event-detail .date .arrow {
  margin-left: 10px;
  width: 18px;
}

.event-detail .date .dropdown {
  margin-left: 8px;
}

.event-detail .date .dropdown {
  width: 14px;
}
.atendeeCheckbox .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.atendeeCheckbox .ant-checkbox-wrapper:nth-child(1) {
  margin-top: 0 !important;
}
.drop-btn {
  outline: none;
  border: 1px solid #eeeff1;
  background-color: transparent;
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.checkInModal {
  width: 60% !important;
  transform: translate(0, -50%) !important;
  top: 49%;
}
.checkInModal .ant-modal-body {
  padding: 0px 40px;
}
.checkInModal .ant-modal-footer {
  display: none;
}
.addNewChildModal {
  width: 800px !important;
  height: 1000px !important;
}
.checkInOutModal {
  width: 55% !important;
  transform: translate(0, -50%) !important;
  top: 49%;
  .ant-modal-body {
    padding: 0px 40px;
  }
  .ant-modal-footer {
    display: none;
  }
}

.cutom-checkbox {
  display: flex;
  align-items: center;
  .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  .ant-checkbox-inner::after {
    left: 32.5%;
  }
}
.grey {
  background-color: #f5f6f8;
}
.selector-input {
  .ant-select {
    height: 50px !important;
  }
  .ant-select-selection-item {
    line-height: 45px !important;
  }
}
.notes-inp {
  height: 150px !important;
  margin-bottom: 50px;
}
.form-btn {
  background: #2ba7ca;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 30px;
}

.form-btn-secondary {
  background: #ebedf1;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  color: #000;
  font-weight: 500;
  margin-bottom: 30px;
}

.particpant-details {
  min-height: 180px;
  max-height: 400px;
  overflow: auto;
}
.my-input {
  height: 50px;
  border-radius: 12px;
  border: 1px solid #eeeff1;
  padding: 10px;
  font-family: 'Montserrat';
}
.checkInoutHistory {
  min-height: 460px;
  overflow: auto;
}
.checkout-model {
  transform: translate(0, -50%) !important;
  top: 49%;
  .ant-modal-body {
    padding: 0px 40px;
  }
  .ant-modal-footer {
    display: none;
  }
}
.addDropIn-model {
  transform: translate(0, -50%) !important;
  top: 49%;

  .model-height {
    max-height: 700px;
    overflow: auto;
  }
  .model-height::-webkit-scrollbar {
    display: none;
  }
  .model-height {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .ant-modal-body {
    padding: 0px 40px;
  }
  .ant-modal-footer {
    display: none;
  }
}
.customPhoneCode input {
  width: 240px !important;
}
.customPhoneCodeCheckIn input {
  width: 100% !important;
}
.fullWidthUpload {
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    position: static;
    display: block;
    width: 530px !important;
    height: 110px !important;
    object-fit: cover;
    /* object-fit: contain; */
  }
  // .upload-list-picture-card .ant-upload-list-item-thumbnail img {
  //   position: static;
  //   display: block;
  //   width: 530px !important;
  //   height: 40px !important;
  //   position: block;
  // }
  // .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  //   position: static;
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  //   /* object-fit: contain; */
  // }
  .ant-upload-list-picture-card .ant-upload-list-item {
    height: 100%;
    margin: 0;
    width: 534px;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 534px !important;
  }
  .fullWidthUpload .ant-upload-list-picture-card .ant-upload-list-item {
    height: 100%;
    margin: 0;
    width: 530px;
  }
}
.modal-h-child {
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 10px;
}
.modal-h-child::-webkit-scrollbar {
  width: 15px;
}
.modal-h-child::-webkit-scrollbar-thumb {
  background: #2ba7ca;
  border-left: 10px solid #fff;
}
