.add-activity-steps {
  margin-top: 16px;

  .ant-steps-item-icon {
    width: 23px !important;
    height: 22px !important;
    margin: 0 3px 0 0 !important;
  }

  .ant-steps-item-title::after {
    top: 12px !important;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon:after {
    color: #2ba7ca;
    background-color: #2ba7ca;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-icon:after {
    color: #2ba7ca;
    background-color: #fff;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #2ba7ca;
    font-size: 11px;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    font-size: 11px;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #2ba7ca;
  }
}
.add-activity-forms {
  height: 450px;
}

.checkbox {
  .ant-checkbox {
    color: #2ba7ca !important;
    margin-top: -5px;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #2ba7ca !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2ba7ca !important;
    border-color: #2ba7ca !important;
  }
}

.main-inventory-container {
  height: auto;
  min-height: 430px;

  &.no-inventory-main-contianer {
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .no-supplies-height {
    height: 100%;
  }
}

.supply-add {
  height: auto;
  min-height: 440px;
  padding: 0 0 20px 0;

  .empty-container {
    height: 440px;
  }
}

.supply-view {
  .img-container {
    width: 100%;
    height: 300px;
    border-radius: 12px;

    img {
      border-radius: 12px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.big-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2ba7ca;
  border-radius: 12px;
  width: 70%;
  padding: 12px 24px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.small-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  background-color: #2ba7ca;
  outline: 0;
  color: #fff;
  cursor: pointer;
}
