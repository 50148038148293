.create-league-main-content-wapper {
  margin: 30px auto 0 auto;
  padding: 0 100px;
  position: relative;
  padding-bottom: 60px !important;
}

.create-league-main-content-cotainer {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.ant-form-item-control-input-content label span:last-child {
  text-align: left !important;
  margin-top: -5px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
}

@keyframes rotate {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.animate-rotate {
  animation: rotate 0.3s linear;
}
