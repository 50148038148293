.history {
  p {
    display: flex;
    align-items: center;
    strong {
      padding-right: 0.8em;
      padding-left: 0.8em;
    }

    strong {
      display: flex;
    }
  }
}
