.dispFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//new styels
.img_frontside_view {
  background-color: #f7f8f9;
  border-radius: 20px 20px 0 0;
  position: relative;
  height: 60px;
  padding: 10px;
  top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}
.arrow_icon.arrow_next {
  right: -14px;
}

.arrow_icon.arrow_prev {
  left: -14px;
}
.document_img_slider.addactivity_img .arrow_icon.arrow_prev {
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  left: 10px;
}
.document_img_slider.addactivity_img .arrow_icon.arrow_next {
  top: 50%;
  margin: 0;
  right: 10px;
  transform: translateY(-50%);
}
.document_img_slider {
  max-width: 339px;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  filter: drop-shadow(0px 1px 8px rgba(99, 103, 103, 0.2));
  position: relative;
}
.status_additional_skill {
  background: #ff6961;
  border-radius: 100px;
  align-items: center;
  flex-direction: row;
  display: flex;
  color: #fff;
  height: 28px;
  padding: 8px 10px;
  gap: 8px;
  font-size: 10px;
}
.document-img-slider .ant-carousel .slick-dots li {
  width: 8px;
  height: 8px;
}

.document-img-slider .ant-carousel .slick-dots li button {
  width: 8px;
  height: 8px;
  background: #000;
  border-radius: 50%;
  opacity: 0.2;
}

.document_img_slider .ant-carousel .slick-dots li.slick-active {
  width: auto;
}

.document_img_slider .ant-carousel .slick-dots li.slick-active {
  width: auto;
}

.document_img_slider .ant-carousel .slick-dots li.slick-active button {
  background-color: #000;
  opacity: 1;
}
.arrow_icon {
  background: #ffffff;
  border: 1px solid #ebedf1;
  box-shadow: 0px 12px 24px rgb(68 68 68 / 10%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 23%;
  bottom: 0;
  margin: auto 0;
  z-index: 99;
  cursor: pointer;
}
.arrow_icon.arrow_next {
  right: -14px;
}
.document_img_slider .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}
.member_divider {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 15px;
}

.members_Scrollbar {
  max-height: 140px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
}

.review_card_main_bg .ant-card.ant-card-bordered.review_progress_card {
  padding-top: 22px;
  padding-bottom: 22px;
  margin-bottom: 20px;
}

.delete_icon_reviews {
  width: 21px;
}

.review_card_main_bg {
  padding-right: 5px;
}

.reviews_progress_bar {
  margin-bottom: 10px;
  max-width: 363px;
}

.reviews_progress_bar span {
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  color: #000;
  margin-right: 12px;
  white-space: nowrap;
}

.reviews_progress_bar .ant-progress-outer {
  width: calc(100% - 5px);
}

.reviews_progress_bar span.ant-progress-text {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0;
  display: inline-block;
}
.ant-progress-bg,
.ant-progress-inner {
  border-radius: 0 !important;
}
