.credit-page {
  width: 98%;
  margin-left: 2%;
  padding: 30px;
}
.total {
  background-color: rgba(235, 237, 241, 0.5);
  padding: 16px;
  border-radius: 12px;
  margin-top: 24px;
}
.txt-position .ant-select-selection-item {
  position: relative;
  top: 4px;
}
.countergroup {
  display: grid;
  grid-template-columns: 40% 15% 45%;
  .counter {
    img {
      width: 60px;
    }
  }
  .inp-counter {
    width: 80px;
    height: 50px;
    border: 1px solid rgba(235, 237, 241, 1);
    margin: 0px 6px;
    text-align: center;
  }
  .inp-counter::-webkit-outer-spin-button,
  .inp-counter::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.active-family {
  background-color: rgba(235, 237, 241, 1);
  padding: 10px !important;
  border-radius: 12px;
  margin: 0;
  margin-top: 20px;
  display: flex;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  width: 90%;
}
.disabled-button {
  background-color: grey;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  color: #fff;
  height: auto !important;
  border: none;
  cursor: none;
}

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   width: 190px !important;
// }

span.anticon.anticon-search{
  margin-top: 6px;
}
