.dropOutUpload {
  .ant-upload.ant-upload-select-picture-card {
    width: 400%;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 400px;
  }
}
.custom-upload .ant-upload-list-item .anticon-close-circle {
  display: none;
}
