@import '../../../../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../../../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css';

.step2-main-container {
  width: 80%;
  margin: auto;
  padding: 30px;

  @media screen and (max-width: 1680px) {
    width: 90%;
  }

  @media screen and (max-width: 1500px) {
    width: 100%;
  }

  @media screen and (max-width: 920px) {
    padding: 60px 30px 30px 30px;
  }
}

.event-button-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//weahter card
.color-white-light {
  color: #fefeff;
}
.weather-card-container {
  background: linear-gradient(180.05deg, #47bfdf 0%, #4a91ff 100%);
  overflow: hidden;
  padding: 10px 16px;
  border: none;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  h3 {
    margin-top: 8px;
    span {
      margin-top: -14px;
    }
  }
  .current-weather-img {
    width: 140px;
    height: 140px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .carousel-cont {
    width: 400px;

    @media screen and (max-width: 1366px) {
      width: 300px;
    }

    @media screen and (max-width: 1166px) {
      width: 220px;
    }

    @media screen and (max-width: 1024px) {
      width: 200px;
    }

    .slick-slide {
      width: 80px !important;
    }

    .ant-carousel .slick-prev {
      width: 34px;
      height: 34px;
      background-image: url('../../../../../../assets/Images/chevron-left.svg');
    }

    .ant-carousel .slick-next {
      right: -20px;
      width: 34px;
      height: 34px;
      background-image: url('../../../../../../assets/Images/chevron-right.svg');
    }
  }

  .week-weather-wrapper {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 13px;
    z-index: 9;
    width: 80px !important;

    &.current {
      background-color: rgba(254, 254, 255, 0.2);
      border-radius: 9999px;
      z-index: 0;
    }

    .weekly-weather-img {
      width: 40px;
      height: 40px;
    }
  }

  .overlfow-cont {
    width: 100%;
    overflow: hidden;
  }
}

//calendar and schedule
.calendar-schedule-container {
  display: flex;
  gap: 70px;
  align-items: start;

  @media screen and (max-width: 1366px) {
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .calendar-cont {
    width: 360px;
    height: auto;

    .schedule-calendar {
      width: 360px;

      .react-calendar__month-view__weekdays__weekday {
        abbr {
          text-decoration: none !important;
        }
      }

      .react-calendar__month-view__days {
        border: 1px solid #ebedf1;
        border-radius: 4px;
        margin-top: 6px;
      }

      .react-calendar__tile {
        margin-bottom: 2px;
        margin-top: 2px;

        abbr {
          z-index: 1;
          position: relative;
          color: #000;
          font-family: Montserrat !important;
          font-size: 14px;
          font-weight: 400;
        }

        &:disabled {
          background-color: transparent;
        }
      }
      .availalbe-date {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background-color: #ebedf1;
          top: 2px;
          left: 7px;
        }

        &.current-selected {
          &::before {
            background-color: #2b6bca;
          }

          abbr {
            color: #fff;
          }
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .activities-cont {
    flex-grow: 1;
  }
}

// activity calendar
.activity-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin: 16px auto 0 auto;

  .carret-cont {
    width: 32px;
    height: 32px;
    border: 1px solid #ebedf1;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.activity-scheduel-calendar {
  margin-top: 24px;
  border: 0 none;
  .e-date-header-container {
    display: none;
  }

  .e-content-wrap {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .e-left-indent-wrap {
    .e-schedule-table {
      display: none;
    }
  }

  // .e-schedule-table {
  //   tbody {
  //     tr {
  //       &:nth-of-type(1) {
  //         height: 10px !important;
  //       }
  //     }
  //   }
  //   tr {
  //     height: 100px;
  //   }
  // }

  // .e-content-table {
  //   thead {
  //     display: none;
  //   }
  //   tbody {
  //     tr {
  //       height: 100px;
  //     }
  //   }
  // }

  table,
  tbody,
  tr,
  td {
    border: none;
  }

  .e-header-cells {
    border: none !important;
  }
  .e-time-cells {
    border-bottom: none !important;
  }
  .e-time-slots {
    span {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #000;
    }
  }

  .e-work-cells {
    background: #fff !important;
    border-bottom: 1px solid #ebedf1;

    &.e-alternate-cells {
      border: none;
    }
  }
}
