.item-card {
  margin: 16px 0;
}

.item-image {
  // width: 230px,
  //  height: 130px,
  //   borderRadius: 8px,

  width: 90%;
  height: 130px;
  border-radius: 8px;
}
// @media (max-width: 2200px) {
//   .item-image {
//     width: 280px;
//     height: 150px;
//     border-radius: 8px;
//   }
// }

.item-details {
  padding: 0 16px;
  // width: 800px;
  height: 130px;
  border-right: 1px dashed lightgray;
  //   gap: 16px;
}

.item-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.item-location {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.45);
}

.item-price {
  font-size: 16px;
  font-weight: bold;
  margin: 4px 0;
}

.item-buttons {
  display: flex;
  gap: 8px;
  margin: 8px 0;
}

.item-button {
  background-color: #e1e3e3;
  color: #000229;
  //   background: #000229;
  font-weight: 500;
  font-size: 12px;
  font-family: Montserrat;

  //   border: 1px solid #E1E3E3
  border: none;

  // padding: 5px 12px 5px 8px;
  // gap: 8px;
  border-radius: 100px;
  // opacity: 0px;
}

.item-updated {
  display: inline;
  margin: 8px 0;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.45);

  //styleName: H5;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
}
.updateTime {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #000000;
  margin-top: 40px;
  margin-left: 20px;
}
.priceStyle {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  margin: 8px 0;
  border-left: 1px solid;
  margin-left: 5px;
}
.dollarRate {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  color: #908f98;
}

// .item-card {
//   margin: 16px 0;
//   width: 100%;
// }

// .item-image {
//   width: 230px;
//   height: 130px;
//   border-radius: 8px;
// }

// .item-details {
//   padding: 0 16px;
//   width: 100%;
//   height: auto;
//   border-right: 1px dashed lightgray;
// }

// .item-title {
//   margin: 0;
//   font-size: 1.25rem; // Responsive font size
//   font-weight: bold;
// }

// .item-location {
//   margin: 8px 0;
//   color: rgba(0, 0, 0, 0.45);
//   font-size: 1rem; // Responsive font size
// }

// .item-price {
//   font-size: 1rem; // Responsive font size
//   font-weight: bold;
//   margin: 4px 0;
// }

// .item-buttons {
//   display: flex;
//   flex-wrap: wrap; // Allow buttons to wrap on smaller screens
//   gap: 8px;
//   margin: 8px 0;
// }

// .item-button {
//   background-color: #e1e3e3;
//   color: #000229;
//   font-weight: 500;
//   font-size: 0.875rem; // Responsive font size
//   font-family: Montserrat;
//   border: none;
//   border-radius: 100px;
//   padding: 5px 12px;
// }

// .item-updated {
//   display: inline;
//   margin: 8px 0;
//   margin-right: 5px;
//   color: rgba(0, 0, 0, 0.45);
//   font-family: Montserrat;
//   font-size: 0.875rem; // Responsive font size
//   font-weight: 500;
//   line-height: 1.5rem;
//   text-align: left;
// }

// .updateTime {
//   font-family: Montserrat;
//   font-size: 0.875rem; // Responsive font size
//   font-weight: 500;
//   line-height: 1.5rem;
//   text-align: left;
//   color: #000000;
//   margin-top: 40px;
// }

// .priceStyle {
//   font-family: Montserrat;
//   font-size: 0.875rem; // Responsive font size
//   font-weight: 400;
//   line-height: 1.5rem;
//   text-align: left;
//   margin: 8px 0;
//   border-left: 1px solid;
//   padding-left: 5px;
// }

// .dollarRate {
//   font-family: Montserrat;
//   font-size: 0.875rem; // Responsive font size
//   font-weight: 400;
//   line-height: 1.5rem;
//   text-align: left;
//   color: #908f98;
// }

// @media (max-width: 768px) {
//   .item-card {
//     margin: 8px 0;
//   }

//   .item-details {
//     padding: 0 8px;
//     height: auto;
//     border-right: none;
//   }

//   // .item-image {
//   //   width: 100%;
//   //   height: auto;
//   // }

//   .item-buttons {
//     justify-content: center; // Center buttons on small screens
//   }

//   .updateTime {
//     margin-top: 20px;
//     text-align: center;
//   }
// }

// @media (max-width: 480px) {
//   .item-title {
//     font-size: 1rem;
//   }

//   .item-price {
//     font-size: 0.875rem;
//   }

//   .item-buttons {
//     flex-direction: column; // Stack buttons on top of each other on very small screens
//   }
// }

// @media (max-width: 1464px) {
//   .item-image {
//     width: 190px;
//     height: 110px;
//     border-radius: 8px;
//   }
// }
// @media (max-width: 1880px) {
//   .item-image {
//     width: 190px;
//     height: 110px;
//     border-radius: 8px;
//   }
// }
