.modal-content {
  padding: 20px;
}

.ticket-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ticket-item h4 {
  margin: 0;
}

@media screen and (max-width: 600px) {
  .ticket-list {
    font-size: 14px;
  }

  .ticket-item {
    padding: 10px;
  }
}
.ModalStyle {
  width: Fill (520px) px;
  height: Hug (193px) px;
  padding: 12px 16px 20px 16px;
  gap: 12px;
  border-radius: 15px;
  backdrop-filter: blur(27.182817459106445px);

  box-shadow: 0px 1px 8px 0px #63676733;
}
