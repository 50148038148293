.bg-hr {
  background-color: #ebedf1 !important;
  height: 1px !important;
  width: 100% !important;
  margin-top: 15px;
}
.linkEdit {
  color: #2ba7ca;
  text-decoration: underline;
  font-size: 15px;
  padding-left: 20px;
  padding-top: 5px;
  cursor: pointer;
}
.smal-txt {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.para {
  font-size: 14px;
  font-weight: 400;
}
.alin-center {
  align-items: center;
}
.tabel {
  margin-top: 30px;
  border: 1px solid #edf0f1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.tabel .header {
  background-color: #dbe1e3;
  padding: 15px 25px;
  display: grid;
  grid-template-columns: 30% 30% 30% 25%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.list .listItem {
  display: grid;
  grid-template-columns: 30% 30% 30% 25%;
  padding: 20px 25px;
  border-bottom: 1px solid #dbe1e3;
}
.listItem .device {
  display: flex;
  align-items: center;
}
.device h4 {
  padding-left: 10px;
}
.subtitle {
  color: #939598;
  font-size: 14px;
  font-weight: 400;
}

.red {
  color: #ff6961;
  text-decoration: underline;
}

.ant-modal-header {
  border: none !important;
}
.ant-modal-body {
  padding-top: 0;
}
.inputGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputGroup:nth-child(2),
.inputGroup:nth-child(3),
.inputGroup:nth-child(4),
.inputGroup:nth-child(6) {
  margin-top: 20px;
}

.inp-label {
  margin-bottom: 10px;
}
.my-input {
  height: 50px;
  border-radius: 12px;
  border: 1px solid #eeeff1;
  padding: 10px;
  font-family: 'Montserrat';
}
.my-input-box input,
.my-input-box .ant-select-selector {
  height: 50px;
  border-radius: 12px;
  border: 1px solid #eeeff1;
  padding: 10px;
  font-family: 'Montserrat';
}

.my-input-box .ant-form-item-control-input {
  margin-top: 0;
}
.my-input-box input::placeholder {
  color: #939598;
}
.my-input-box span .anticon::after {
  height: 0px !important;
  width: 0px !important;
}
.input-flex {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 48% 4% 48%;
}
.popup-modal {
  width: 600px !important;
  top: 50% !important;
  transform: translate(0, -50%) !important;
}
.popup-modal .btstyle {
  display: flex;
  width: 100%;
  position: relative;
  left: 59%;
}
.popup-header {
  padding-top: 40px !important;
  padding-bottom: 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.popup-header img {
  cursor: pointer;
}
.ant-modal-close {
  display: none;
}
.popup-modal .ant-modal-body {
  padding: 0px 40px;
}
.popup-modal .ant-modal-footer {
  display: none;
}
.popup-footer {
  display: flex;
  justify-content: end;
  padding: 40px;
  padding-right: 0;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .popup-footer {
    justify-content: center;
    flex-direction: column-reverse;
    padding-left: 0;
  }
  .btn-sec {
    margin-right: 0px !important;
    margin-top: 20px;
    cursor: pointer;
  }
}
.btn-prim {
  background-color: #2ba7ca;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  color: #fff;
  height: auto !important;
  border: none;
  cursor: pointer;
}
.btn-prim:hover {
  border: 2px solid #2ba7ca;
  background-color: transparent;
  color: #2ba7ca;
  padding: 10px 22px;
}
.btn-sec {
  background-color: #ebedf1;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  padding: 12px 34px;
  border: none;
  color: #000;
  margin-right: 20px;
}
label {
  font-weight: 500;
  color: #000;
}
.subtitlePara {
  color: #939598;
  padding-top: 10px;
  font-size: 14px !important;
}
.changeModal .ant-form-item-control-input {
  margin-top: 20px;
}
.ant-input-affix-wrapper,
.antd-country-phone-input {
  height: 50px !important;
  border-radius: 12px !important;
  border: 1px solid #eeeff1 !important;
  padding: 10px !important;
  font-family: 'Montserrat';
}
.upload-main {
  border: 2.5px dashed #939598;
  margin-top: 20px;
  padding: 14px;
}

.upload-area {
  background-color: #eaf6fa;
  height: 300px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.upload-title {
  font-size: 14px;
  color: #000;
}
.upload-title label {
  text-decoration: underline;
  color: #000;
  padding-left: 4px;
  cursor: pointer;
}
.uploadModal {
  height: 500px;
}
.img-prev {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.avatar-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.image-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image-area .ant-upload {
  position: relative;
}
.device {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.deactivate {
  text-align: center;
  padding-top: 40px;
}
.deactivate h2 {
  font-weight: 600;
}
.deactivate p {
  font-weight: 400;
  font-size: 14px;
  color: #000;
  padding-top: 8px;
}
.btn-red {
  background-color: #ff6961;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  color: #fff;
}
.defooter {
  justify-content: center !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.deModal {
  width: 450px !important;
}
.deModal .ant-modal-footer {
  display: none;
}
.pointer {
  cursor: pointer !important;
}
