.dragText {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
}
.uploadText {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  color: #2ba7ca;
}
.selectOption {
  width: 540px;
  height: 51px;
  padding: 15px 16px 15px 16px;
  gap: 0px;
  border-radius: 12px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  justify-content: space-between;
  opacity: 0px;
}
.attributeText {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #939598;
  margin-top: 5px;
}
.popup-header-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;

  img {
    cursor: pointer;
  }
}

.import-modal,
.mapping-modal {
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;

    img {
      cursor: pointer;
    }
  }

  .ant-upload-drag-icon {
    margin-bottom: 16px;
  }

  .dragText {
    font-size: 16px;

    text-align: center;
    margin-bottom: 8px;

    .uploadText {
      color: #1890ff;
      cursor: pointer;
    }
  }

  .ant-upload-hint {
    text-align: center;
  }

  .prim-sm-btn {
    background: #2ba7ca;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      background: #d9d9d9;
      color: #999;
      cursor: not-allowed;
    }
  }

  .flex {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;

    button {
      margin-left: 16px;
    }
  }
}

.mapping-modal {
  .schema-mapping {
    margin-top: 10px;

    .mapping-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      padding: 7px;
      border-radius: 12px;
      background: #ebedf180;

      .attribute-label {
        font-size: 14px;
        width: 100% !important;
      }

      .csv-column-select {
        width: 60%;
      }
    }
  }

  .prim-sm-btn {
    background: #2ba7ca;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      background: #d9d9d9;
      color: #999;
      cursor: not-allowed;
    }
  }
}
.schema-Text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-top: 15px;
}
.schema-mapping-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border-bottom: 1px solid #ebedf1;

  .attribute-header,
  .csv-column-header {
    font-weight: 600;
    font-size: 14px;
    margin-right: 120px;
  }
}
.fleximg {
  display: flex;
  width: 70%;
}

.review-modal {
  .popup-header-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .attributeText {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #939598;
    margin-top: 5px;
  }

  .schema-Text {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .schema-mapping-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .schema-mapping {
    .mapping-row {
      display: flex;
      align-items: center;

      margin-bottom: 10px;
      padding: 10px;
    }

    .fleximg-2 {
      display: flex;
      align-items: center;
      width: 55%;
    }

    .attribute-label {
      font-size: 14px;
      margin-right: 10px;
    }

    .csv-column-label {
      font-size: 14px;
      text-align: start;
      font-weight: 500;
    }
  }
}

.completeModal {
  .recordFill {
    width: 261.5px;
    height: 85px;
    padding: 16px;
    gap: 12px;
    border-radius: 12px;
    background: rgba(235, 237, 241, 1);
  }
  .failedText {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #ff6961;
    margin-left: 10px;
  }
  .text {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #000000;
  }
  .SuccessText {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #2b6bca;
    margin-left: 10px;
  }
}
.logsModal {
  width: 600px;
  height: 570px;

  padding: 30px 0px 0px 0px;
  gap: 10px;
}
.logMessage {
  border-bottom: 1px solid #ebedf1 !important;
  font-weight: 600;
}
.errorMessage {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
}

th {
  border-bottom: 1px solid #ebedf1;
  padding: 5px;
}
