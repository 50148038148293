$main: #2ba7ca;
$light-blue: rgba(234, 246, 250, 1);
$text-sml: rgba(147, 149, 152, 1);
$border-colr: rgba(235, 237, 241, 1);
$red: #ff6961;
$gray: #ebedf1;
$blueText: #3274b6;
$lightGray: #f5f6f8;
h1,
h2,
h3,
h4,
h5,
h6,
label {
  color: #000;
}
.bg-white {
  background-color: white;
}
.bg-blue {
  background-color: $main;
}
.bg-dark-gray {
  background-color: $gray;
}

.bg-shadow {
  box-shadow: 1px 1px 21px 10px rgba(99, 103, 103, 0.14);
  -webkit-box-shadow: 1px 1px 21px 10px rgba(99, 103, 103, 0.14);
  -moz-box-shadow: 1px 1px 21px 10px rgba(99, 103, 103, 0.14);
}
.bg-grey {
  background-color: $border-colr;
}
.bg-greyLight {
  background-color: $lightGray;
}
.bg-transparent {
  background-color: transparent;
}
.hidden {
  display: hidden;
}
.block {
  display: block;
}
.inline {
  display: inline !important;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.grid {
  display: grid;
}
.grid-50 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}
.grid-55-45 {
  display: grid;
  grid-template-columns: 55% 45%;
  grid-column-gap: 20px;
}
.grid-60-40 {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid-70-30 {
  display: grid;
  grid-template-columns: 70% 30%;
}

.grid-33 {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-start {
  justify-content: start;
}
.justify-end {
  justify-content: end;
}
.justify-items {
  justify-items: center;
}
.align-start {
  align-items: start;
}
.align-end {
  align-items: end;
}
.align-center {
  align-items: center;
}
.item-center {
  justify-items: center;
}
.h-full {
  height: 100%;
}
.h-fit {
  height: fit-content;
}
.h-10px {
  height: 10px;
}
.h-15px {
  height: 15px;
}
.h-20px {
  height: 20px;
}
.h-30px {
  height: 30px;
}
.h-36px {
  height: 36px;
}

.h-40px {
  height: 40px;
}
.h-50px {
  height: 50px;
}

.h-60px {
  height: 60px;
}
.h-100px {
  height: 100px;
}
.h-2 {
  height: 2rem !important;
}
.h-3 {
  height: 3rem !important;
}
.h-10 {
  height: 10rem !important;
}
.h-14 {
  height: 14rem !important;
}
.h-15 {
  height: 15rem !important;
}
.h-17rem {
  height: 17rem;
}
.h-18rem {
  height: 18rem !important;
}
.h-19rem {
  height: 19rem;
}
.h-20 {
  height: 20rem !important;
}
.h-22rem {
  height: 22rem;
}
.h-25rem {
  height: 25rem;
}
.h-26rem {
  height: 26rem;
}
.h-27rem {
  height: 27rem;
}
.h-30rem {
  height: 30rem;
}
.h-33rem {
  height: 33rem;
}
.h-35rem {
  height: 35rem;
}
.h-40rem {
  height: 40rem;
}
.h-45rem {
  height: 45rem;
}
.h-50rem {
  height: 50rem;
}
.max-h-26rem {
  max-height: 26rem;
}
.h-20vh {
  height: 20vh;
}
.h-40vh {
  height: 40vh;
}
.h-50vh {
  height: 50vh;
}
.h-60vh {
  height: 60vh;
}
.h-70vh {
  height: 70vh;
}
.h-80vh {
  height: 80vh;
}
.h-85vh {
  height: 85vh;
}
.h-90vh {
  height: 90vh;
}
.h-100vh {
  height: 100vh;
}
.h-80p {
  height: 80%;
}
.h-90p {
  height: 90%;
}
.h-95p {
  height: 95%;
}
.h-100p {
  height: 100%;
}
.h-fill {
  height: calc(100vh - 150px);
}
.line-0 {
  line-height: normal !important;
}
.text-gray {
  color: $text-sml !important;
}
.text-darkBlue {
  color: $blueText;
}
.text-black {
  color: #000;
}
.text-white {
  color: #fff;
}
.text-prim {
  color: $main;
}
.text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center;
}
.text-underline {
  text-decoration: underline;
}
.text-8 {
  font-size: 8px;
}
.text-10 {
  font-size: 10px;
}
.text-12 {
  font-size: 12px;
}
.text-14 {
  font-size: 14px;
}
.text-16 {
  font-size: 16px;
}
.text-15 {
  font-size: 15px !important;
}
.text-18 {
  font-size: 18px;
}
.text-20 {
  font-size: 20px;
}
.text-24 {
  font-size: 24px;
}
.text-36 {
  font-size: 36px;
}
.text-40 {
  font-size: 40px;
}
.title-14 {
  font-size: 14px;
  font-weight: 600;
}

.w-400 {
  font-weight: 400;
}
.w-500 {
  font-weight: 500;
}
.w-600 {
  font-weight: 600;
}
.w-700 {
  font-weight: 700;
}
.p-15px {
  padding: 15px;
}
.p-20px {
  padding: 20px;
}
.p-30px {
  padding: 30px;
}
.py-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}
.py-7px {
  padding-top: 7px;
  padding-bottom: 7px;
}
.py-10px {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-12px {
  padding-top: 12px;
  padding-bottom: 12px;
}
.py-15px {
  padding-top: 15px;
  padding-bottom: 15px;
}
.py-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-30px {
  padding-top: 30px;
  padding-bottom: 30px;
}
.py-40px {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pt-5px {
  padding-top: 5px;
}
.pt-10px {
  padding-top: 10px;
}
.pt-15px {
  padding-top: 15px;
}
.pt-20px {
  padding-top: 20px;
}
.pt-25px {
  padding-top: 25px;
}
.pt-30px {
  padding-top: 30px;
}
.pt-35px {
  padding-top: 35px;
}
.pt-40px {
  padding-top: 40px;
}

.pr-5px {
  padding-right: 5px;
}
.pr-10px {
  padding-right: 10px;
}
.pr-20px {
  padding-right: 20px;
}
.pr-30px {
  padding-right: 30px;
}
.pl-5px {
  padding-left: 5px;
}
.pl-10px {
  padding-left: 10px;
}
.pl-15px {
  padding-left: 15px;
}
.pl-20px {
  padding-left: 20px;
}
.pl-30px {
  padding-left: 30px;
}

.pb-5px {
  padding-bottom: 5px;
}
.pb-10px {
  padding-bottom: 10px;
}
.pb-15px {
  padding-bottom: 15px;
}
.pb-20px {
  padding-bottom: 15px;
}
.pb-30px {
  padding-bottom: 30px;
}
.pb-40px {
  padding-bottom: 40px;
}
.px-5px {
  padding-left: 5px;
  padding-right: 5px;
}
.px-8px {
  padding-left: 8px;
  padding-right: 8px;
}
.px-10px {
  padding-left: 10px;
  padding-right: 10px;
}
.px-12px {
  padding-left: 12px;
  padding-right: 12px;
}
.px-15px {
  padding-left: 15px;
  padding-right: 15px;
}
.px-20px {
  padding-left: 20px;
  padding-right: 20px;
}
.px-30px {
  padding-left: 30px;
  padding-right: 30px;
}
.px-30px {
  padding-left: 30px;
  padding-right: 30px;
}
.px-35px {
  padding-left: 35px;
  padding-right: 35px;
}
.px-40px {
  padding-left: 40px;
  padding-right: 40px;
}
.px-55px {
  padding-left: 55px;
  padding-right: 55px;
}
.m-10px {
  margin: 10px;
}
.m-20px {
  margin: 20px;
}
.m-40px {
  margin: 40px;
}
.mt-3px {
  margin-top: 3px;
}
.mt-5px {
  margin-top: 5px;
}
.mt-8px {
  margin-top: 8px;
}

.mt-10px {
  margin-top: 10px;
}
.mt-12px {
  margin-top: 12px;
}
.mt-15px {
  margin-top: 15px;
}
.mt-20px {
  margin-top: 20px;
}
.mt-25px {
  margin-top: 25px;
}
.mt-30px {
  margin-top: 30px;
}
.mt-40px {
  margin-top: 40px;
}

.-mt-10px {
  margin-top: -10px;
}

.-mt-15px {
  margin-top: -15px;
}

.-mt-20px {
  margin-top: -20px;
}

.mb-0px {
  margin-bottom: 0px !important;
}

.mb-5px {
  margin-bottom: 5px;
}
.mb-8px {
  margin-bottom: 8px;
}
.mb-10px {
  margin-bottom: 10px;
}
.mb-16px {
  margin-bottom: 16px;
}
.mb-20px {
  margin-bottom: 20px;
}
.mb-24px {
  margin-bottom: 24px;
}
.mb-30px {
  margin-bottom: 30px;
}
.mb-40px {
  margin-bottom: 40px;
}
.ml-0px {
  margin-left: 0;
}
.ml-5px {
  margin-left: 5px;
}
.ml-10px {
  margin-left: 10px;
}
.ml-50px {
  margin-left: 90px;
}
.ml-16px {
  margin-left: 16px;
}
.ml-20px {
  margin-left: 20px;
}
.ml-30px {
  margin-left: 30px;
}
.ml-40px {
  margin-left: 40px;
}
.mr-5px {
  margin-right: 5px;
}
.mr-8px {
  margin-right: 8px;
}
.mr-10px {
  margin-right: 10px;
}
.mr-20px {
  margin-right: 20px;
}
.mr-30px {
  margin-right: 30px;
}
.mr-40px {
  margin-right: 40px;
}

.my-10px {
  margin: 10px auto;
}
.my-15px {
  margin: 15px auto;
}
.my-20px {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my-30px {
  margin-top: 30px;
  margin-bottom: 30px;
}
.my-40px {
  margin: 40px auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-10px {
  margin: auto 10px;
}
.mx-15px {
  margin-left: 15px;
  margin-right: 15px;
}
.mx-20px {
  margin: auto 20px;
}
.mx-30px {
  margin-left: 30px;
  margin-right: 30px;
}
.mx-40px {
  margin: auto 40px;
}
.radius-4 {
  border-radius: 4px;
}
.radius-6 {
  border-radius: 6px;
}
.radius-8 {
  border-radius: 8px;
}
.radius-10 {
  border-radius: 10px;
}

.radius-12 {
  border-radius: 12px !important;
}
.radius-16 {
  border-radius: 16px !important;
}
.radius-rounded {
  border-radius: 50%;
}
.radius-full {
  border-radius: 9999px;
}
.border-1px-blue {
  border: 1px solid $main;
}
.border-1px {
  border: 1px solid $border-colr;
}
.border-right {
  border-right: 1px solid $border-colr;
}
.border-left {
  border-left: 1px solid $border-colr;
}
.border-top {
  border-top: 1px solid $border-colr;
}
.border-btm {
  border-bottom: 1px solid $border-colr;
}
.border-prim {
  border: 1px solid $main;
}
.border-none {
  border: none;
}
.w-full {
  width: 100%;
}
.w-fit {
  width: fit-content !important;
}
.w-10p {
  width: 10%;
}
.w-20p {
  width: 20%;
}
.w-30p {
  width: 30%;
}

.w-33p {
  width: 33%;
}

.w-35p {
  width: 35%;
}
.w-40p {
  width: 40%;
}
.w-50p {
  width: 50%;
}
.w-55p {
  width: 55%;
}
.w-60p {
  width: 60%;
}
.w-70p {
  width: 70%;
}
.w-80p {
  width: 80%;
}
.w-90p {
  width: 90%;
}
.w-10px {
  width: 10px;
}
.w-15px {
  width: 15px;
}
.w-20px {
  width: 20px;
}
.w-25px {
  width: 25px;
}
.w-30px {
  width: 30px;
}
.w-36px {
  width: 36px;
}
.w-40px {
  width: 40px;
}
.w-80px {
  width: 80px;
}
.w-130px {
  width: 130px;
}
.w-150px {
  width: 150px;
  height: 150px;
}
.w-180px {
  width: 180px;
}
.w-200px {
  width: 200px;
}
.w-300px {
  width: 300px;
}
.w-400px {
  width: 400px;
}
.w-550px {
  width: 550px !important;
}
.w-650px {
  width: 650px !important;
}
.overflow-auto {
  overflow: auto;
}
.overflow-auto-y {
  overflow-y: auto;
}
.overflow-hidden-x {
  overflow-x: hidden;
}
.borderd-btn {
  border: 1.2px solid $main;
  color: $main;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
}
.btn-secondary {
  border: none;
  color: $main;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  padding: 9px 40px;
  background: $gray;
  color: #000;
  border: 1.2px solid transparent;
}
.btn-disabled {
  border: 1px solid $border-colr;
  height: auto;
  padding: 12px 24px;
  color: $text-sml;
  border-radius: 12px;
  background-color: transparent;
  cursor: not-allowed;
}

.bordered-prim-btn {
  border: 1.2px solid $main;
  color: $main;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 40px;
  background: transparent;
}
.bordered-grey-btn {
  border: 1.2px solid #fff;
  width: 112px;
  height: 37px;
  font-weight: 500;
  // padding: 12px 24px 12px 24px;
  gap: 13px;
  // border-radius: 8px 0px 0px 0px;

  background: #ebedf1;
}
.simple-btn {
  border: none;
  outline: none;
  padding: 12px;
  color: $main;
  background: transparent;
}
.eventAttendee-model {
  overflow: auto;
  width: 600px;
  height: 400x;
  padding: 30px 0px 0px 0px;
  gap: 10px;
}
.formatText {
  width: 46px;
  height: 12px;
  gap: 0px;

  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  margin-bottom: 10px;
  text-align: left;
}
.addExport-model {
  overflow: auto;
  width: 600px;
  height: 550px;

  padding: 30px 0px 0px 0px;
  gap: 10px;
}
.eventExport-model {
  overflow: auto;
  width: 600px;
  height: 540px;

  padding: 30px 0px 0px 0px;
  gap: 10px;
}
.Check-InOut-model {
  overflow: auto;
  width: 600px;
  height: 380px;
  padding: 30px 0px 0px 0px;
  gap: 10px;
}
.mentor-model {
  overflow: auto;
  width: 600px;
  height: 400x;
  padding: 30px 0px 0px 0px;
  gap: 10px;
}
.radioText {
  width: 197px;
  height: 12px;
  gap: 0px;

  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
}
.btn-gray {
  border: none;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  padding: 9px 40px;
  background: $gray;
  color: #000;
  border: 1.2px solid transparent;
}
.prim-btn {
  border: none;
  color: $main;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  padding: 9px 40px;
  background: $main;
  color: #fff;
  border: 1.2px solid transparent;
}
.request-model {
  overflow: auto;
  width: 600px;
  height: 400x;
  padding: 30px 0px 0px 0px;
  gap: 10px;
  opacity: 0px;
}
.prim-sm-btn {
  border: none;
  color: $main;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 24px;
  background: $main;
  color: #fff;
}
.prim-btn:hover {
  background-color: transparent;
  border: 1.2px solid $main;
  color: $main;
}
.btn-borderless {
  background-color: transparent;
  border: none;
  padding: 10px 16px;
  cursor: pointer;
}
.chips {
  background-color: $border-colr;
  border-radius: 8px;
  padding: 5px 10px;
  width: 120px;
}
.allergyChips {
  background-color: $border-colr;
  border-radius: 15px;
  padding: 6px;
}
.pointer {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
}
.cursor-disabled {
  cursor: progress;
}
.text-edit {
  color: $main !important;
}

.grey-txt {
  color: $text-sml;
}
.danger-txt {
  color: $red;
}
.prim-txt {
  color: $main;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.top-0 {
  top: 0;
}
.bottom-0 {
  bottom: 0;
}
.bottom-40 {
  bottom: 40px;
}
.bottom-50 {
  bottom: 50px;
}

.selector {
  height: 50px;
}
.selector .ant-select-selection-item {
  top: 0px;
}
.filter-btn {
  border: 1px solid $border-colr;
  border-radius: 100px;
  padding: 8px 12px;
  background-color: transparent;
}

.filter-btn-click {
  background-color: $border-colr;
  border: 1px solid $border-colr;
  border-radius: 100px;
  padding: 8px 12px;
}
.gap-5px {
  gap: 5px;
}
.gap-10px {
  gap: 10px;
}
.gap-16px {
  gap: 16px;
}
.gap-20px {
  gap: 20px;
}
.gap-25px {
  gap: 25px;
}
.gap-30px {
  gap: 30px;
}
.gap-40px {
  gap: 40px;
}
.gap-60px {
  gap: 60px;
}

.gap-y-20px {
  gap: 20px 0px;
}

// scroller

.scroller {
  padding-right: 0.4rem;
}

.scroller::-webkit-scrollbar {
  width: 3px;
}

.scroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroller::-webkit-scrollbar-thumb {
  background: #2ba7ca;
  border-radius: 20px;
}

// style={disabled ? {pointerEvents: "none", opacity: "0.4"} : {}}
.no-pointer-events {
  pointer-events: 'none';
  opacity: '0.4';
}

.shadow {
  box-shadow: 0px 1px 8px 0px #63676733;
}

.shadow-box {
  box-shadow: 0px 1px 12px 0px #63676733;
}
.text-space-prewrap {
  white-space: pre-wrap;
}
.text-space-nowrap {
  white-space: nowrap;
}
.text-elipsis {
  text-overflow: ellipsis;
}

.verification-email-color {
  background: #eaf6fa;
  padding: 10px;
  border-radius: 15px;
}
.verification-email-sent {
  margin-bottom: 1rem; /* Add some space below */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.verification-email-sent p {
  margin: 0; /* Remove default margin */
  font-weight: bold;
}

.custom-dropdown-overlay {
  max-height: 300px; /* Set the max height you want */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 14px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add a shadow */
}

span.ant-slider-dot {
  border: 2px solid $text-sml;
}
span.ant-slider-dot:nth-last-child(1) {
  left: 97% !important;
  // border: 2px solid $text-sml;
}
