.border-1 {
  border-bottom: 1px solid rgba(238, 239, 241, 1);
}
.org-list {
  height: 350px;
  overflow: auto;
  padding-top: 1em;
}
.org-modal {
  height: 700px;
  .ant-modal-body {
    padding-left: 0px I !important;
  }
  .custom-select .ant-select-open {
    display: none;
  }
  .ant-pagination-options {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
.org-active {
  border: none;
  background-color: rgba(235, 237, 241, 1);
  padding: 20px !important;
  border-radius: 12px;
  margin: 0;
  margin-top: 20px;

  z-index: 3333333;
}

.org-select-field .ant-select-selection-item {
  display: flex;
  align-items: center;
  color: #000 !important;
  font-weight: 500;
}
