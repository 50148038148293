$white: #fff;
$grey_color: #ebedf1;
$black: #000;
$danger: #fe0900;
//react calender
.react-calendar {
  width: 100% !important;
  border: none !important;
}
.react-calendar__tile--now {
  background-color: transparent !important;
  color: #000 !important;
}
.react-calendar__tile--active:enabled {
  background-color: transparent !important;
  color: #000 !important;
}
.react-calendar__tile--hasActive {
  background-color: #3274b6 !important;
  color: #fff;
}
.selected {
  background-color: #3274b6 !important;
  color: #ebedf1 !important;
  border-radius: 50% !important;
  border: 0.5rem solid #fff !important;
}
.react-calendar__tile--active:enabled.selected {
  background-color: #3274b6 !important;
  color: #ebedf1 !important;
  border-radius: 50% !important;
}
.react-calendar__tile abbr {
  font-size: 15px !important;
  line-height: 22px !important;
}
.calendar-customzation .event-dates {
  background-color: #ebedf1 !important;
  color: #000 !important;
  border-radius: 50% !important;
  border: 0.5rem solid #fff !important;
}

//small calendar customization
.calendar-customzation .react-calendar {
  border-radius: 6px !important;
  background: none !important;
}
.calendar-customzation .react-calendar__tile:disabled {
  background-color: transparent !important;
  opacity: 0.7 !important;
}
.calendar-customzation .react-calendar__navigation__prev2-button,
.calendar-customzation .react-calendar__navigation__next2-button {
  display: none;
}
.calendar-customzation .react-calendar__navigation {
  align-items: center !important;
}
.calendar-customzation .react-calendar__navigation__label__labelText {
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: 'Montserrat' !important;
}
.calendar-customzation .react-calendar__navigation__prev-button,
.calendar-customzation .react-calendar__navigation__next-button {
  border: 1px solid $grey_color !important;
  margin: 10px !important;
  width: 34px !important;
  height: 36px !important;
  border-radius: 6px !important;
}
.calendar-customzation .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
  font-size: 12px !important;
  font-family: 'Montserrat' !important;
  font-weight: 600 !important;
  color: $black !important;
}
.calendar-customzation .react-calendar__month-view__days {
  border: 1px solid $grey_color !important;
  border-radius: 6px !important;
  margin-top: 12px !important;
}
.calendar-customzation .event-dates {
  background-color: #ebedf1 !important;
  color: $black !important;
  border-radius: 50% !important;
  border: 0.5rem solid $white !important;
}
.calendar-customzation .react-calendar__tile {
  height: 55px !important;
}
.calendar-customzation .react-calendar__tile {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.calendar-customzation .react-calendar__tile--active:enabled {
  background-color: #2b6bca !important;
  color: $white !important;
  border-radius: 50% !important;
  border: 0.5rem solid $white !important;
}

//schedule
.unavailable_cell {
  background-color: lightgray;
  border: 1px solid gray;
  padding: 4px;
  text-align: center;
}
.scheduleMainContent {
  display: flex;
  .scheduleLeftContainer {
    width: 35%;
    margin-right: 10rem;
  }
  .sceduleRightContainer {
    width: 65%;
    margin-left: 3rem;
  }
}
.dayView {
  width: 100%;
  margin-left: 0rem;
}
.selectorSchedule {
  display: flex;
  justify-content: end;
}
.btnToolbar {
  width: 32px;
  height: 32px;
  border: 1px solid #ebedf1 !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
}

.input-style {
  outline: none;
  border: 1px solid #eeeff1;
  border-radius: 10px;
}

.ant-popover-inner-content {
  border-radius: 8px;
}
input {
  height: 50px !important;
}
.custom-input-field {
  padding: 14px 16px !important;
  border-radius: 12px !important;
  border: 1px solid $grey_color;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: $black !important;
  box-shadow: none !important;
  width: 100% !important;
  height: 50px;
}
.custom-field-label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: $black;
  margin-bottom: 10px;
  display: block;
  &.required-input {
    position: relative;
    &::after {
      content: '*';
      font-size: 14px;
      margin-left: 2px;
      color: $danger;
    }
  }
}
.btn {
  padding: 17px 32px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  height: auto;
  border: none;
  &.extra-x-space {
    padding-left: 42px;
    padding-right: 42px;
  }
  &.org-bg {
    color: $white !important;
  }
  &.gray-bg {
    color: $black !important;
  }
  .back-arrow {
    margin-right: 10px;
    vertical-align: middle;
  }
  &.dark-gray-bg {
    color: $white !important;
  }
  &.dark-gray-bg {
    color: $white !important;
  }
}

.ant-dropdown::-webkit-scrollbar {
  width: 3px;
}

.ant-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ant-dropdown::-webkit-scrollbar-thumb {
  background: #2ba7ca;
  border-radius: 20px;
}

.ant-switch {
  width: 36px;
  height: 28px;
  background-color: #ebedf1;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 7px);
}
.ant-switch-handle {
  height: 24px;
  width: 24px;
  border-radius: 50%;

  &::before {
    border-radius: 50%;
  }
}

.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}
.py-20 {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}
.green-txt-500 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #a6ca16;
}

.ant-steps {
  gap: 16px;
}

.step-styling {
  &.ant-steps-item-active {
    .ant-steps-item-title {
      color: #000;
    }
  }
  &.ant-steps-item {
    flex: none !important;
    gap: 10px;
  }
  .ant-steps-item-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .ant-steps-item-icon {
    width: 24px;
    margin: 0;
  }
  .ant-steps-item-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    padding-right: 10px;
    margin-right: 40px;

    &:after {
      top: 12px;
      background-color: #ebedf1;
    }
  }
}

.phone-number-form {
  .react-tel-input {
    input.form-control {
      width: calc(100% - 90px);
      margin-left: auto;
      margin-right: 0;
      display: block;
      height: auto;
      padding: 14px 16px;
      border-radius: 12px;
      border: 1px solid #ebedf1;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      box-shadow: none !important;
    }

    .flag-dropdown {
      // padding: 14px 16px;
      // border-radius: 12px;
      // border: 1px solid #ebedf1;
      border: none;
      background: transparent;

      &.open {
        border-radius: 12px;
      }

      .selected-flag {
        // padding-left: 0;
        width: 70px;
        padding: 14px 16px;
        border-radius: 12px;
        border: 1px solid #ebedf1;

        &.open {
          border-radius: 12px;
        }

        .arrow {
          border-top: none;

          &::after {
            position: absolute;
            content: '\f107';
            color: $black;
            font-family: 'Font Awesome 6 free';
            font-weight: 600;
            right: -15px;
            top: -8px;
          }

          &.up {
            border-bottom: none;

            &::after {
              content: '\f106';
            }
          }
        }
      }
    }
  }
}

.combined-input-field {
  padding: 8px 16px;
  border-radius: 12px;
  border: 1px solid #ebedf1;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  box-shadow: none !important;
  width: 100%;
}

input.text-left[type='number'] {
  text-align: left !important;
}
