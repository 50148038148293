$light-blue: rgba(234, 246, 250, 1);
.person-active {
  background-color: $light-blue;
  padding: 15px;
  width: 100%;
  border-radius: 12px;
}
.grid-notes {
  display: grid;
  grid-template-columns: 30% 45% 25%;
}
.notes-bg {
  background-image: url('../../../../../assets/Images/bg-notes.svg');
  background-size: cover;
}

.notes-area {
  background-color: $light-blue;
  padding: 18px;
  border-radius: 8px;
  margin-top: 10px;
  width: fit-content;
  min-width: 400px;
  font-weight: 500;
  .bold {
    font-weight: 600 !important;
    display: inline;
    padding-left: 4px;
    padding-right: 4px;
  }
}
.addNote {
  position: relative;
  top: -40%;
  bottom: 0;
}
.tagged-area {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.add-note-modal {
  width: 35% !important;
  height: 600px;

  .note-modal-height{
    height: 550px;
    overflow-y: auto;
  }

  .ant-modal-body {
    padding: 40px 40px;
  }
  .ant-modal-footer {
    display: none;
  }
}

.select-event-modal {
  width: 35% !important;

  .ant-modal-body {
    padding: 40px 40px;
  }
  .ant-modal-footer {
    display: none;
  }
}

.tagged-menu-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #eeeff1;
}

.tagged-menu-button.selected-member {
  background-color: #2ba7ca;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.tagged-member-list {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.slected-members {
  padding: 6px 10px;
  background-color: #ebedf1;
  border-radius: 999px;
}

.notes-select-field .ant-select-selector{
  padding: 10px 18px;
}
.image-upload{
  padding: 20px;
  border: 1px dotted #000;
  background-color: #ebedf1;
}

.img-upload-notes {
  height: 70px;
  width: 70px;
  position: relative;

  .closing-icon {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 2px;
    width: 30px;
  }
}

.image_preview_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.image_preview_container {
  max-width: 80%;
  height: 100%;
  max-height: 768px;
  margin: auto;
}

