.custom-event-template {
  padding: 10px;
  display: flex;
  gap: 10px;
  position: relative;
  .custom-event-header {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #000 !important;
    font-family: 'Montserrat', sans-serif !important;
  }
  .custom-event-time {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #000229;
    font-family: 'Montserrat', sans-serif !important;
  }

  .custom-menu-opener {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    right: 5px;
    top: 6px;

    .dot-container {
      display: flex;
      gap: 2px;

      .dot {
        width: 2px;
        height: 2px;
        background-color: #000;
        border-radius: 50%;
      }
    }
  }
}
