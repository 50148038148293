@import 'assets/variables.scss';

.event-list-icon {
  cursor: pointer;
  margin: 16px;
  display: none;
  position: absolute;
  z-index: 9999999;

  @media screen and (max-width: 920px) {
    display: block;
  }
}

.create-event-side-bar {
  width: 506px !important;
  left: 0;
  z-index: 999;

  @media screen and (max-width: 920px) {
    position: absolute;
    height: 100vh;
    display: none;
    padding-top: 30px;

    &.sidebar-open {
      display: block;
    }
  }

  @media screen and (min-width: 0px) and (max-width: 1600px) {
    width: 430px !important;
  }

  .ant-layout-sider-children {
    width: 506px !important;
    @media screen and (min-width: 0px) and (max-width: 1600px) {
      width: 430px !important;
    }
  }
}

.event-step-bar {
  &.ant-steps-item-process {
    box-shadow: 0px 12px 24px 0px #4444441a;
  }

  padding: 13px 16px;
}

.create-btn {
  background-color: $main;
  border-radius: 12px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  height: 50px;
  border: none;

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.enabled {
    opacity: 1;
    cursor: pointer;
  }
}

.progress-bar-btn {
  padding: 6px 24px;
  font-size: 13px;
  font-weight: 500;
  border: 2px solid $main;
  border-radius: 9999px;
  cursor: pointer;

  &.active {
    background-color: $main;
    color: #fff;
  }
  &.non-active {
    background-color: transparent;
    color: $main;
  }
}
