@import url('https://fonts.gstatic.com');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  color: #000000;
  margin-bottom: 0;
}

.logo span {
  color: #2ba7ca;
}

.avtar-btn {
  border: 1px solid #ebedf1;
  box-sizing: border-box;
  border-radius: 18px;
  height: 36px;
}

.avtar-btn .anticon svg {
  color: #000229;
  opacity: 0.4;
}

.side-bar-nav {
  flex: 0 0 272px !important;
  max-width: 272px !important;
  min-width: 272px !important;
  width: 100%;
  position: fixed;
  top: 65px;
  left: 0;
  height: 100%;
  padding-top: 30px;
  background: #e5e5e5;
}

.light-btn {
  background-color: #fff;
  border: 1px solid #ebedf1;
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-block;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  height: 100%;
  color: #000229;
}

.gray-btn {
  max-width: 138px;
  width: 100%;
  height: 43px;
  border: 1px solid #ebedf1 !important;
  background-color: #ebedf1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000229;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.gray-btn:hover {
  background: #fff;
}

.blue-btn {
  background-color: #2ba7ca;
  border: 1px solid #2ba7ca;
  padding: 10px 20px;
  display: inline-block;
  box-sizing: border-box;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  height: 100%;
  line-height: 21px;
  border-radius: 8px;
}

.blue-btn:hover {
  background-color: #fff;
  color: #000229;
}

.blue-btn-radius {
  border-radius: 100px;
}

.stepper-button {
  padding: 14px 40px 14px 40px;
  height: auto;
  border: none;
  background: #2ba7ca !important;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #fff !important;
  border: 1px solid transparent !important;
}

.stepper-button:hover {
  background: transparent !important;
  color: #2ba7ca !important;
  border-color: #2ba7ca !important;
}

.cancel-button {
  padding: 14px 40px 14px 40px;
  height: auto;
  border: none;
  background: #e5e5e5 !important;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #000 !important;
  border: 1px solid transparent !important;
}

.cancel-button:hover {
  background: transparent !important;
  border-color: #000 !important;
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 66px 12px 56px;
  max-width: 220px;
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  background-color: #2ba7ca;
  color: #fff;
  border: 1px solid #2ba7ca;
  border-radius: 100px;
  transition: all 0.5s ease-in-out;
}

.action-btn:hover {
  background-color: #fff;
  color: #2ba7ca;
}

.job-request-sidebar {
  height: 100vh;
  background: #e5e5e5;
}

.ant-layout.ant-layout-has-sider {
  padding-top: 74px !important;
}

.table-card {
  box-shadow: none !important;
  border: none !important;
  height: 100vh;
}

.right-search-block {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 780px) {
  .right-width {
    width: 120px;
  }

  .event-search-box .right-width {
    width: auto;
  }
}

.search-input-box {
  position: relative;
  margin-right: 30px;
  width: 100%;
}

.search-input-box span.anticon.anticon-search {
  top: 10px;
}

.user-search {
  width: 100%;
}

input.search-input-group {
  padding-left: 44px;
  height: 38px;
  margin: auto 0;
  background: #ffffff;
  border: 1px solid #ebedf1;
  border-radius: 10px;
  width: 100%;
}

input.search-input-group::placeholder {
  color: #9ca5b0;
}

.header-nav.ant-layout-header input.search-input-group {
  max-width: 343px;
  height: 36px;
}

.login-form .ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 41px;
  background: #2ba7ca;
  border-radius: 8px;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  border: 1px solid #2ba7ca;
  color: #ffffff;
}

.forgot-password {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 28px;
}

.forgot-password a {
  font-weight: 600;
  color: #2ba7ca;
}

.ant-input,
.ant-input-affix-wrapper {
  border: 1px solid #ebedf1;
  box-sizing: border-box;
  border-radius: 12px;
  height: 38px;
  padding: 10px 16px;
  width: 100%;
}

.ant-input-affix-wrapper .ant-input {
  border-radius: 0px;
  border: none;
  height: auto;
  display: inherit;
}

input.search-input-group:focus-visible {
  outline: none;
}

.nav-btn {
  border-color: #2ba7ca;
  color: #2ba7ca;
  justify-content: center;
  align-self: center;
}

.nav-btn-bell {
  justify-content: center;
  align-self: center;
  padding-left: 29px;
  padding-right: 14px;
}

.nav-btn-bell svg {
  color: rgba(0, 2, 41, 1);
  vertical-align: middle;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.event-top-bar {
  display: flex;
}

.event-btns {
  border-radius: 10px;
  margin-left: 10px;
}

.event-btns-card {
  border-radius: 10px;
  margin-left: 10px;
  border: none;
}

.dot-btn-dropdown-menu {
  width: 169px;
  border-radius: 4px !important;
}

.lang-icon-block {
  text-align: right;
  padding: 20px;
}

.login-footer {
  text-align: center;
}

.login-form-container {
  position: relative;
  height: 100%;
  background-color: #fff;
  display: flex;
}

.left-login-form {
  max-width: 340px;
  margin: 0 auto;
  background-color: #fff;
  padding: 40px 0;
  height: 100%;
  position: relative;
  padding-bottom: 80px;
}

.phone-login-dont-acct {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
}

.right-col-blue {
  background: url('../Images/login.svg');
  background-size: cover;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
}

.login-form {
  max-width: 500px;
}

.login-form .login-form-forgot {
  float: right;
}

.login-form .ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  height: 41px;
  background: #2ba7ca;
  border-radius: 8px;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.break-line {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background: #fff;
  z-index: 1;
  width: 100%;
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #202733;
  margin-top: 32px;
}

.ant-input-affix-wrapper .ant-input {
  border-radius: 0px;
  border: none;
  height: auto;
  display: inherit;
}
.w-500 {
  font-weight: 500;
}
.ant-form-item-control-input-content span {
  font-family: 'Montserrat', sans-serif;
  /* font-weight: 500; */
  font-size: 14px;
  line-height: 15px;
  display: inline-flex;
  text-align: center;
  margin-bottom: 0;
  align-items: center;
}

span.anticon.anticon-eye-invisible.ant-input-password-icon:after {
  display: none !important;
}

.ant-form-item-control-input-content span .ant-select-selection-item-content {
  align-self: center;
}

.social-btn-group {
  flex-direction: column;
}

.social-btn {
  background: #ffffff;
  border: 1px solid #eeeff1;
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 327px;
  width: 100%;
  height: 41px;
  margin-bottom: 16px;
  font-weight: 500;
  position: relative;
  padding-left: 65px;
}

.social-btn .ant-space-item:first-child {
  position: absolute;
  left: 35px;
  top: 50%;
  transform: translateY(-50%);
}

.social-btn .ant-space {
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.social-btn a span {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  color: #1b2633;
  display: inline-block;
  padding-left: 60px;
}

.signup-link p {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1b2633;
}

.signup-link a {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2ba7ca;
}

.profile-tabs .ant-tabs-tab {
  justify-content: center;
  margin: 0;
  padding: 12px 32px !important;
  display: block;
  text-align: center;
  max-width: unset !important;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  font-family: 'Montserrat', sans-serif;
}

.profile-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  text-transform: uppercase;
  color: #000000;
  padding: 0;
}

.sub-heading {
  font-size: 12px;
  color: gray;
}

.user-title {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-self: center;
}

.user-title .nav-btn {
  height: 36px;
}

.user-avtar {
  border-radius: 100px;
  border-width: 20px;
  padding-left: 15px;
  align-items: center;
}

span.anticon.anticon-search {
  position: absolute;
  left: 18px;
  top: 24px;
  font-size: 19px;
  color: #9ca5b0;
}

.ant-layout-header {
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  box-shadow: 0px 1px 8px 0px #63676799;
  height: 74px;
  align-items: center;
}

.header-nav.ant-layout-header {
  padding-left: 32px;
  padding-right: 40px;
}

.header-avtar {
  float: left;
  margin: 16px 12px 16px 0;
}

.header-title {
  float: left;
}

.content-center {
  justify-content: center;
  align-items: center;
}

.padding-20 {
  padding: 20px;
}

.title-fontStyle {
  font-weight: 700;
  font-size: 24px;
}

.graph-fontStyle {
  font-weight: 700;
  font-size: 26px;
}

.margin-20 {
  margin-top: 80px;
}

.height-100 {
  height: 100%;
}

.font-color {
  color: gray;
}

.font-title-member {
  font-weight: 500;
  font-size: 20px;
}

.overflow-x-auto {
  overflow-x: auto;
  margin-top: 20px;
}

.ant-breadcrumb {
  background-color: #fff;
  margin: 0;
  padding: 30px 50px;
}

.ant-layout-content {
  margin: 20px 30px 0;
}

.language-div {
  justify-content: center;
  display: flex;
  align-items: center;
}

.form-label,
.input-block {
  text-align: left;
  background: #fafafa;
  padding: 8px;
  border-bottom: 1px solid #d9dcd4;
  border-right: 1px solid #d9dcd4;
}

.form-block,
.form-block2,
.form-block3 {
  border-top: 1px solid #d9dcd4;
  border-left: 1px solid #d9dcd4;
}

.input-block {
  background-color: #fff;
}

.anticon-edit {
  color: #000 !important;
  padding-left: 10px;
}

.input-text h5.ant-typography,
.ant-typography h5 {
  font-size: 14px;
  font-weight: 500;
}

.form-label-course {
  height: 269px;
  border-right: 1px solid #d9dcd4;
  border-bottom: 1px solid #d9dcd4;
  background: #fafafa;
  padding: 8px;
}

.form-label-env {
  height: 98px;
  border-right: 1px solid #d9dcd4;
  border-bottom: 1px solid #d9dcd4;
  background: #fafafa;
  padding: 8px;
}

.form-label-team {
  height: 147px;
  border-right: 1px solid #d9dcd4;
  border-bottom: 1px solid #d9dcd4;
  background: #fafafa;
  padding: 8px;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.spinner {
  display: flex;
  justify-self: center;
  align-items: center;
  text-align: center;
}

.ant-layout-sider-children .ant-menu {
  background-color: #e5e5e5;
}

.ant-layout-sider {
  background-color: #e5e5e5 !important;
}

.card-table {
  background: #ffffff;
  border: 1px solid #ebedf1;
  border-radius: 20px;
}

.ant-card.ant-card-bordered {
  position: relative;
  overflow: hidden;
  padding: 20px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: none;
}

.manage-status-items .ant-card-head-wrapper {
  padding-right: 40px;
}

.events-card .ant-card.ant-card-bordered {
  position: relative;
  overflow: hidden;
  padding: 0px !important;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 6px 0px #1b26331a;
}

.ant-card-head {
  border-bottom: none;
  padding: 0;
}

.ant-card-body {
  padding: 0px;
  position: relative;
}

.card-number {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #5e94ad;
}

.card-label-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000229;
  display: inline-block;
  padding: 7px 12px;
  border: 1px solid #dbe1e3;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 16px;
}

.cricle {
  position: absolute;
  right: -35px;
  top: -44px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  overflow: hidden;
}

.cricle span {
  position: relative;
  left: -23px;
  top: 32px;
}

.mentor-dropdown {
  background: #ffffff;
  border: 1px solid rgba(219, 225, 227, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 186px;
  width: 100%;
  min-height: 36px;
  text-align: left;
  position: relative;
  padding-right: 30px;
}

.mentor-dropdown span.anticon.anticon-down {
  position: absolute;
  right: 13px;
  top: 12px;
}

.mentor-search {
  top: 11px !important;
  left: 40% !important;
}

.manage-widgets-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.singup-container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding-top: 80px;
}

.singup-container .ant-form {
  margin-top: 44px;
}

.singup-container .ant-form-item-control-input-content span {
  display: block;
}

.signup-next-btn {
  background: #07839c;
  border-radius: 10px;
  max-width: 327px;
  width: 100%;
  height: 52px;
  box-sizing: border-box;
  display: block;
}

.signup-next-btn span {
  margin-bottom: 0;
  color: #ffffff;
}

.social-heading {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #202733;
}

.singup-container .social-btn-group {
  margin-top: 54px;
}

.singup-container .login-pra {
  text-align: center;
  width: 100%;
}

.singup-container .social-btn-group {
  align-items: center;
}

.singup-container label {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 10px;
  color: #1b2633;
  display: block;
}

.singup-container .login-pra p {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #1b2633;
}

.manage-widgets {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #2ba7ca;
  padding-left: 10px;
  display: inline-block;
}

.main {
  background-color: #fff;
}

.signup_steps-header {
  border-radius: none;
  padding: 25px 25px;
  background-color: #ffffff;
  box-shadow: 0px 1px 8px 0px #63676733;
}

.signup-steps {
  max-width: 910px;
  width: 100%;
  margin: 0 auto;
}

.signup-steps .ant-steps-item-icon {
  background-color: #ebedf1;
  border: 0;
}

.signup-steps .ant-steps-item-icon .ant-steps-icon {
  color: #07839c;
}

.signup-steps .ant-steps-item-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #1b2633 !important;
  margin-top: 4px;
}

.signup-steps .ant-steps-item-title::after {
  top: 12px;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 50%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

.event-box {
  margin-bottom: 30px;
}

.register-box {
  padding: 16px;
  border: 1px solid #ebedf1;
  border-top: none;
}

.register-box button {
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 8px 39px;
  line-height: 0;
  font-size: 13px;
}

p.event-date {
}

.today-events-header {
  border: 1px solid #ebedf1;
  padding: 5px 15px 5px 15px;
  border-radius: 8px 8px 0 0;
  margin-top: 20px;
}

.today-events-header span {
  font-size: 14px;
  font-weight: 600;
  color: #232626;
}

.site-calendar-demo-card {
  width: 280px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.news-feed-block .ant-card.ant-card-bordered {
  border-radius: 10px;
  margin-top: 15px;
  border-top: 1px solid #f0f0f0;
}

.news-feed-block .ant-card-meta {
  margin-bottom: 10px;
}

.news-feed-block .ant-card-meta-title {
  margin-bottom: 0 !important;
  line-height: 18px;
  font-size: 14px;
  color: #333333;
}

.news-feed-block .ant-card-meta-description {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #9ca5b0;
}

.news-content {
  font-size: 14px;
  color: #000229;
}

.ant-dropdown.ant-dropdown-placement-bottom {
  width: 170px;
  padding: 0;
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  border-radius: 10px;
}

span.anticon.anticon-delete,
span.anticon.anticon-edit {
  padding-left: 0;
  font-size: 20px;
  color: #000;
}

span.ant-dropdown-menu-title-content p {
  margin-bottom: 0;
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light
  li {
  padding: 8px 15px;
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light
  li:last-child
  span
  p {
  color: #e8769f;
}

.add-btn:first-child {
  margin-top: 5px;
}

.add-btn {
  padding: 15px 50px;
}

.ant-card.ant-card-bordered {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border-radius: 16px;
}

.ant-card-head {
  border-bottom: none;
  padding: 0;
}

.ant-card-body {
  padding: 0px;
  position: relative;
}

.card-number {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #5e94ad;
}

.blue-light-card .card-number {
  color: rgba(93, 137, 223, 1);
}

.aesthetic-dark-card .card-number {
  color: rgba(127, 125, 228, 1);
}

.lavender-light-card .card-number {
  color: rgba(250, 112, 136, 1);
}

.card-label-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000229;
  display: inline-block;
  padding: 7px 12px;
  border: 1px solid #dbe1e3;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 16px;
}

.ant-card-head-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000229;
  margin-bottom: 12px;
}

.manage-status-items .ant-card-head-title {
  padding-top: 0;
  padding-bottom: 0;
}

.manage-status-items .ant-card-head {
  min-height: auto;
}

.cricle {
  position: absolute;
  right: -78px;
  top: -109px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  overflow: hidden;
  box-shadow:
    inset 0px 0px 6px rgb(27 38 51 10%),
    0px 0px 4px rgb(27 38 51 5%);
}

.cricle span {
  position: relative;
  left: -24px;
  top: 22px;
}

li {
  list-style: none;
}

.manage-widgetsDrawer {
  display: flex;
  justify-content: end;
  flex-direction: row;
  padding: 2%;
  border-radius: 10%;
}

.manage-widgets-font {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.stepperNextButton {
  display: flex;
  justify-content: end;
  flex-direction: row;
  border-radius: 10px;
}

.stepperNextButton .ant-btn-lg {
  border-radius: 10px;
  background: #2ba7ca;
  padding: 8px 28px;
}

.stepper-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.stepper-button {
  padding: 14px 40px 14px 40px;
  height: auto;
  border: none;
  background: #2ba7ca;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
}
.stepper-back {
  padding: 14px 40px 14px 40px;
  height: auto;
  border: none;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
}

.stepperBackh5 {
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  cursor: pointer;
}

.organizationDetails {
  border: 1px solid #ebedf1;
  box-sizing: border-box;
  border-radius: 12px;
  min-height: 1px;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  flex-grow: 1;
}

.ant-modal-content {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.ant-modal-header {
  background: transparent;
  padding: 5%;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #a6ca16;
  border-color: transparent;
  color: #a6ca16;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #a6ca16;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: #a6ca16;
  opacity: 0.2;
  border: transparent;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: 1.5px solid #a6ca16;
}

.ant-picker {
  border-radius: 10px;
}

.ant-modal-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.date-picker {
  width: 100%;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #c0ebf3 !important;
  color: rgb(0, 0, 0);
}

.add-service-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #2ba7ca;
}

.ant-modal-close-x {
  background: #ebedf1;
  border-radius: 55%;
  opacity: 0.5 !important;
  margin: 12px;
  width: 32px;
  height: 32px;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ability .ant-slider:hover .ant-slider-track {
  background-color: #ffffff;
}

.ability .ant-slider-track {
  background-color: #ffffff;
}

.ability .ant-slider-handle {
  border: solid 4px #a6ca16;
  height: 16px;
  width: 16px;
  left: 50% !important;
}

.ability.emoji-items span.ant-slider-mark-text {
  top: -45px;
}

.ability .ant-slider-step .ant-slider-dot {
  width: 16px;
  height: 16px;
  top: -6px;
}

.ant-slider.ability {
  width: calc(100% - 40px);
}

.ant-slider.ability.emoji-items.ant-slider-horizontal.ant-slider-with-marks {
  margin-bottom: 0;
}

.ability .ant-slider-step .ant-slider-dot:after {
  position: absolute;
  content: '';
  width: 6px;
  height: 6px;
  background: #eeeff1;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ability span.ant-slider-mark-text {
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  color: #000229;
}

.stepper .ant-steps-item-title {
  font-size: 10px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  padding-right: 10px;
  margin-right: 40px;
}

.stepper.event-stepper .ant-steps-item-title {
  font-weight: 600;
  line-height: 21px !important;
  text-transform: uppercase;
  padding-right: 10px;
  margin-bottom: 6px;
  font-size: 10px;
  font-size: 14px;
}

.event-stepper .ant-steps-item-icon {
  background-color: transparent !important;
}

.ql-editor {
  height: 150px;
}

.select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 12px !important;
}

li {
  list-style: none;
}

.manage-widgetsDrawer {
  justify-content: flex-end;
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 23px 30px;
  border-radius: 10%;
}

.site-card-wrapper.page-header {
  margin-top: 0;
}

.widgets-drawer {
  border-radius: 20px;
}

.widgets-drawer .ant-drawer-content {
  border-radius: 20px 0px 0px 20px;
  box-shadow: 0px 1px 12px rgba(99, 103, 103, 0.2);
  backdrop-filter: blur(13.5914px);
  padding-bottom: 30px;
}

.widgets-drawer .ant-drawer-body {
  padding: 22px 16px;
}

.widgets-drawer .ant-drawer-body ul li {
  margin-bottom: 17px;
}

.widgets-drawer .ant-drawer-body ul li span {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  padding: 0;
}

.widgets-drawer .ant-drawer-body ul li span.ant-checkbox {
  margin-right: 12px;
}

.widgets-drawer .ant-drawer-body ul li span b {
  font-weight: 500;
}

.manage-widgets-font {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.stepperNextButton {
  display: flex;
  justify-content: end;
  flex-direction: row;
}

.stepperBackButton {
  display: flex;
  justify-content: space-between;
  flex-direction: col;
}

.News-feed-Modal {
  height: 51px;
  width: 113px;
  left: 867px;
  top: 695px;
  border-radius: 10px;
  padding: 14px, 40px, 14px, 40px;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 40px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 40px;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  content: none;
}

.selectedButton {
  background-color: #c0ebf3 !important;
}

.ant-menu-item-selected {
  background-color: #2ba7ca !important;
  color: #ffffff !important;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(90%);
  margin: 5px;
  border-radius: 10px;
}

.ant-layout-sider-children .ant-menu {
  background-color: #e5e5e5;
}

.ant-layout-sider-children {
  margin-top: -0.1px;
  padding-top: 0.1px;
  width: 278px;
  position: fixed;
  background: #e5e5e5;
  overflow-y: auto;
  height: calc(100vh - 65px) !important;
  padding-top: 20px;
}

.ant-layout-sider-children::-webkit-scrollbar {
  width: 8px;
}

.ant-layout-sider-children::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ant-layout-sider-children::-webkit-scrollbar-thumb {
  background: #2ba7ca;
  border-radius: 20px;
}

.ant-layout-sider-children::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Calendar {
  box-shadow: none;
}

.org-profile-header {
  width: 100%;
  background-color: #fff;
  padding: 40px 40px 30px 40px;
}

.org-profile-header-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  display: inline;
  line-height: 24px;
  text-transform: capitalize;
  margin-left: 10px;
}

.org-profile-stat {
  display: flex;
  justify-content: space-between;
}

.org-profile-action-button {
  border-radius: 20px;
  background: #2ba7ca;
  text-align: center;
  width: 220px;
}

.org-profile-stat .card {
  background-color: #f5f6f8;
}

.primary-color {
  color: hwb(193 17% 21%);
}

.contact-information img {
  padding: 5px;
  border-radius: 15px;
  background-color: #ebedf1;
}

.org-profile .ant-tabs-tab-active {
  background-color: #ebedf1;
  color: #000;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: center;
  padding: 10px;
}

.org-profile .ant-tabs-ink-bar {
  background: none;
}

.org-profile .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000;
}

.news-submit-button {
  position: absolute;
  top: 90%;
  left: 80%;
}

.job-request-container {
  height: 100%;
  padding: 10px;
}

.job-request-card-container {
  margin-bottom: 20px;
}

.job-request-content {
  display: flex;
  justify-content: space-between;
}

.propose-mentor-card {
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid #ebedf1 !important;
  border-radius: 0px !important;
  margin: 5px;
}

.primary {
  background-color: #2ba7ca;
}

.secondary {
  background-color: #ebedf1 !important;
}

#job-request-layout {
  background-color: #f0f2f5 !important;
  margin-top: 0px;
  height: 100% !important;
}

#job-request-layout .ant-layout.ant-layout-has-sider {
  padding-left: 278px !important;
  padding-top: 70px !important;
  height: 100% !important;
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0px !important;
}

.available-mentor-checkbox {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
}

.add-service-text p {
  color: #a6ca16;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 0;
  margin-left: 16px;
}

.add-service-text .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #ebedf1;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}

.event-details-main {
  background-color: #e5e5e5;
}

.event-details-text {
  padding-left: 20px;
}

.participants-block {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.participants-block h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000229;
}

.event-details-btn {
  background: #ebedf1;
  border-radius: 100px;
  padding: 7px 24px;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  border: none;
}

.event-details-btn:last-child {
  background-color: #2ba7ca;
  color: #fff;
}

.event-details-tabs {
  margin-top: 20px;
  width: 100%;
}

.event-details-tabs .ant-tabs-nav-list .ant-tabs-tab {
  background-color: transparent;
  border: none !important;
}

.event-details-tabs .ant-tabs-tab {
  margin-left: 20px !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 12px 36px !important;
  color: black !important;
}

.event-details-tabs
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #ebedf1 !important;
  border-radius: 10px 10px 0 0 !important;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #ebedf1 !important;
  border-radius: 10px 10px 0 0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000229;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.participants-profile {
  display: flex;
}

.participants-profile-name {
  padding-left: 20px;
}

.participants-card {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #ebedf1;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}

.participants-profile-name p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #000229;
}

.participants-profile-name p:last-child {
  color: #9ca5b0;
  font-weight: 400;
}

.participants-checkin:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 45%;
  height: 100%;
  border: 1px solid #ebedf1;
  display: block;
}

.participants-checkin ul li {
  display: inline-flex;
  padding-left: 10px;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: right;
  color: #000229;
}

.participants-checkin ul li:first-child {
  font-weight: 600;
}

.setting-list {
  background-color: rgb(134 118 232 10%);
}

.setting-list li.ant-list-item {
  border-bottom: 1px solid rgb(137 138 153 10%);
}

.setting-list li.ant-list-item .ant-list-item-meta-avatar {
  align-self: center;
}

.ant-list-split .ant-list-item {
  border-bottom: none;
}

.setting-list h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000229;
}

.switch-div {
  max-width: 430px;
  width: 100%;
  display: flex;
  padding: 16px 20px;
  border: 1px solid #dbe1e3;
  border-radius: 10px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.switch-btn .ant-switch-checked {
  background-color: #a6ca16;
}

.dropdown-setting {
  position: absolute;
  border-radius: 20px;
  background: #ffffff;
  left: 0;
  right: 20px;
  border-color: #ebedf1;
}

.category-dropdown {
  width: 100%;
  color: #000;
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 5px;
}

.switch.ant-switch-checked {
  background-color: #8cc73f;
}

.radio .ant-radio-inner::after {
  background: none;
}

.radio .ant-radio-inner {
  width: 20px;
  height: 20px;
}

.radio .ant-radio-checked .ant-radio-inner {
  border: 5px solid #8cc73f;
}

.activePrimary {
  color: #000 !important;
  background: #c0ebf3 !important;
}

.onetime {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.onetime .datepicker {
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px !important;
}

.flexible {
  margin-top: 10px;
  margin-bottom: 10px;
}

.feedback-checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 1px solid gray;
  color: gray;
  background-color: white;
  padding: 20px 10px;
  border-radius: 50%;
  font-size: 5px;
  font-weight: bold;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

table .ant-table-thead > tr > th {
  background-color: transparent;
}

table .ant-table-thead > tr > th::before {
  position: static !important;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0;
}

.ant-tabs-tab-btn {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #000229;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: transparent;
  opacity: 1;
  border: transparent;
}

.table-responsive {
  width: 100%;
}

.table-responsive .ant-table-content {
  overflow-x: auto;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2ba7ca;
}

.ant-tabs-ink-bar {
  background-color: #2ba7ca;
}

.ant-radio-group {
  width: 100%;
}
.terms {
  font-size: small;
  padding: 0px 6px !important;
  position: relative;
  top: -3px !important;
}
.organization_model .ant-modal-body {
  padding: 0;
}

.organization_model .ant-layout {
  padding: 30px;
  padding-top: 20px;
  border-radius: 0 0 20px 20px;
}

.model-header {
  padding: 30px 30px 0px;
  border-bottom: 1px solid #ebedf1;
}

.model-header .ant-steps::-webkit-scrollbar {
  height: 5px;
}

.model-header .ant-steps::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 99px;
}

.model-header .ant-steps::-webkit-scrollbar-thumb {
  background-color: #2ba7ca;
  outline: 1px solid #2ba7ca;
  border-radius: 99px;
}

.model-header .ant-steps {
  overflow-x: auto;
  display: block;
  white-space: nowrap;
}

.footer-modal {
  padding-top: 20px;
  position: relative;
}

.footer-modal:after {
  content: '';
  position: absolute;
  top: 0px;
  left: -30px;
  bottom: 0;
  right: 0;
  width: 600px;
  height: 1px;
  background-color: #ebedf1;
  display: block;
}

.ant-modal-close {
  top: 20px;
  right: 20px;
}

.model-header h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: inline-block;
  color: #000229;
  margin-bottom: 10px !important;
}

.input-label {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 18px;
  display: inline-block;
  margin-bottom: 10px;
  color: #000;
}

.input-label.text-16 {
  font-size: 16px !important;
}

.input-label.text-18 {
  font-size: 18px !important;
}

input::placeholder {
  color: #898a99;
}

.required-input::after {
  content: '*';
  color: #e32;
  display: inline;
  padding-left: 3px;
  font-size: 15px;
  position: absolute;
  top: 2px;
}

.information-icon {
  display: inline;
  margin-left: 4px;
}

.upload-file .ant-form-item-control-input {
  border: 2px dotted #c9c9c9;
  min-height: 38px;
  max-width: 330px;
  height: 38px;
  width: 100%;
}

.model-header .ant-steps-item-title::after {
  width: 27px;
}

.file-upload-input {
  color: transparent;
  width: 100%;
}

.file-upload-input::-webkit-file-upload-button {
  visibility: hidden;
}

.file-upload-input::before {
  content: '\f574';
  color: black;
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  white-space: nowrap;
  -webkit-user-select: none;
  text-align: center;
  outline: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.add-family {
  margin-top: 20px;
}

.add-family p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-left: 10px;
}

span.ant-steps-icon {
  position: relative;
  visibility: hidden;
  display: inline-block;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: transparent;
  border: none;
}

.ant-steps-item-icon {
  border: none;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon:after {
  color: #a6ca16;
  background-color: #fff;
}

.grey-card {
  background: rgba(235, 237, 241, 0.3);
}

.ant-modal-close svg {
  fill: #000229;
  font-size: 16px;
}

.ant-form-item {
  margin-bottom: 0;
}

.model-header .ant-steps-item {
  flex: 0 0 30%;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 1px solid gray;
  color: gray;
  background-color: white;
  padding: 20px 10px;
  border-radius: 50%;
  font-size: 5px;
  font-weight: bold;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.map-modal .ant-modal-content {
  border-radius: 20px;
  padding: 0px;
  width: 100% !important;
  height: 100% !important;
}

.profile-header {
  width: 100%;
  background-color: #fff;
  padding: 40px;
  margin-bottom: 10px;
}

.parent-profile-contact-info {
  width: 100%;
  background-color: #fff;
  margin-bottom: 5px;
  padding: 20px;
}

.parent-profile-payment-box {
  width: 100%;
  background-color: #fff;
  height: 100%;
  padding: 20px;
}

.parent-profile-creadits-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.grey-card {
  background: #f5f6f8 !important;
}

.parent-profile-container {
  background-color: #e5e5e5 !important;
  width: 100%;
}

.family-profile-container {
  background-color: #fff;
  height: 100vh;
  padding: 20px;
}

.kid-profile {
  width: 100%;
  height: 100%;
}

.kid-profile .card {
  border-radius: 0px;
  padding-top: 40px;
  padding-left: 24px;
}

.settings-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #a6ca16 !important;
  border-color: #a6ca16 !important;
}

.settings-checkbox .ant-checkbox-inner {
  border-radius: 50% !important;
  width: 22px;
  height: 22px;
}

.pra-heading {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000229;
  margin-bottom: 20px;
}

.switch-pra {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000229;
  mix-blend-mode: normal;
  opacity: 0.9;
}

.ant-picker {
  border: 1px solid #ebedf1;
  padding: 7px 16px 7px;
  border-radius: 12px;
}

.radioButton span {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #ebedf1;
  border-radius: 10px;
  height: 100%;
}

.custom-select-field .ant-select.ant-select-in-form-item {
  height: 38px;
}

li.ant-list-item.assignorganization-list-items .antd-select-level {
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
}

.antd-select-level.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 37px !important;
}

.skill-modal label {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #000229;
}

.general-s-heading {
  margin-bottom: 32px !important;
}

.skill-modal .ant-btn-round {
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  border: 1px solid #eeeff1;
  border-radius: 12px;
  color: #000229;
  font-weight: 400;
}

.ant-steps-item-icon:after {
  content: '\f058';
  font-family: 'Font Awesome 5 Free';
  visibility: visible;
  display: inline-block;
  font-size: 16px;
  color: #ebedf1;
  font-weight: bold;
  background: #9ca5b0;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ant-steps-item-finish .ant-steps-item-icon:after {
  color: #a6ca16;
  background-color: #fff;
}

.file-upload-input:hover::before {
  border-color: black;
}

.file-upload-input:active {
  outline: 0;
}

input.secondary.pac-target-input {
  top: 16%;
}

.upload-file .file-upload-input {
  border: 1px dashed #c9c9c9;
  min-height: 38px;
  max-width: 330px;
  height: 38px;
  width: 100%;
  position: relative;
  border-radius: 12px;
}

.model-header .ant-steps-item-title::after {
  width: 27px;
  top: 13px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0px;
}

.model-header .ant-steps-item {
  flex: unset;
}

.app-information-block p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000229;
  margin-bottom: 20px;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: 1px solid #ebedf1;
}

.legal-btn {
  border: 1px solid #ebedf1;
  border-radius: 100px;
  padding: 12px 17px;
  height: 100%;
  max-width: 426px;
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
  position: relative;
}

.legal-btn::after {
  content: '';
  position: absolute;
  background-image: url('../Images/right-arrow.svg');
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  right: 0;
  left: -16px;
  top: 0;
  bottom: 0;
  display: block;
  background-position: right;
}

.legal-btn span {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000229;
}

.ant-col {
  width: 100%;
}

.social-input {
  border: 1px solid #ebedf1;
  border-radius: 12px;
  padding: 10px 16px;
  max-width: 332px;
  width: 100%;
}

.social-switch-div {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.social-switch-div .social-switch-img {
  margin-right: 16px;
}

.social-switch-div .switch-btn {
  margin-left: 16px;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2ba7ca;
}

@media screen and (max-width: 991px) {
  .side-bar {
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
  }

  .ant-layout-sider-children {
    width: 250px !important;
  }

  .side-bar-nav {
    top: 55px;
  }

  .search-input-box {
    margin-right: 10px;
  }

  .header-nav .user-search {
    margin-left: 30px;
  }
}

@media screen and (max-width: 400px) {
  .side-bar {
    flex: 0 0 100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
    width: 100px !important;
  }

  .ant-layout-sider-children {
    width: 100px !important;
  }

  .side-bar-nav {
    top: 55px;
  }

  .search-input-box {
    margin-right: 10px;
  }

  .header-nav .user-search {
    margin-left: 30px;
  }
}

.ant-tabs-tab-btn {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000229;
  padding: 0 20px;
}

.confirm-modal {
  text-align: center;
}

.confirm-modal .ant-modal-confirm-btns {
  float: inherit;
}

.rewards-card {
  min-height: 132px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #2ba7ca;
  border-radius: 8px;
  margin-right: 20px;
}

.rewards-avtar-img {
  margin-bottom: 12px;
}

.rewards-switch-toggle {
  margin-right: 10px;
}

.rewards-edit-btn {
  color: #2ba7ca;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.pra-heading {
  margin-bottom: 15px;
}

.gary-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #252b3e;
  opacity: 0.5;
}

.dropdown-setting .ant-select-selection-placeholder {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000229;
}

.mycalender {
  width: 100% !important;
}

.mycalender
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #2b6bca !important;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.mycalender
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: none !important;
}

.mycalender tbody {
  box-sizing: border-box;
  border: 1px solid #ebedf1;
  border-radius: 4px;
}

.mycalender .ant-badge {
  position: relative !important;
  top: -36px !important;
  left: 17px !important;
}

.mycalender .ant-badge-count {
  z-index: auto;
  width: 3px;
  height: 20px;
  padding: 3px;
  color: #000229;
  font-weight: normal;
  font-size: 7px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ebedf1;
  box-shadow: 0 0 0 1px #fff;
  border-radius: 0px !important;
}

.mycalender thead {
  font-size: 12px;
  font-weight: 600 !important;
  color: #202733;
}

.mycalender .ant-picker-calendar .ant-picker-panel {
  border-bottom: none;
  margin-top: 15px;
  border-top: none;
}

.calender-card {
  padding: 2px !important;
  border-radius: 0px !important;
  height: 39px;
  width: auto;
  border-left: 2px solid #8676e8;
}

.big-calender tbody {
  background-color: #fafafb !important;
}

.big-calender table thead th {
  border: 1px solid #ebedf1 !important;
}

.big-calender table tbody td {
  border: 1px solid #ebedf1 !important;
}

.big-calender .showmore-card {
  padding: 2px !important;
  border-radius: 0px !important;
  background-color: #d9dbe0 !important;
}

.newfamily-modal {
  width: 600px !important;
  height: 851px !important;
}

.profile-tab {
}

.organization-profile-statistics-card {
  width: 100% !important;
  min-height: 68px !important;
  padding: 10px !important;
  text-align: center !important;
}

.organization-profile-statistics-card h6 {
  line-height: 24px;
  color: #333333;
}

.organization-profile-statistics-divider {
  margin-top: 30px;
  margin-bottom: 30px;
}

.status_dropdown {
  border-radius: 10px !important;
}

.showmore {
  font-size: 13px !important;
  cursor: pointer !important;
  font-family: 'Montserrat', sans-serif;
}

.responsive-steper {
  overflow: auto;
}

.responsive-steper .ant-steps-item {
  width: 33.33%;
}

.responsive-steper {
  display: block;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center;
  padding-top: 30px;
  border-bottom: 1px solid #87c440;
}

.responsive-steper .ant-steps-item {
  width: 33.33%;
  overflow: hidden;
}

.events-card .ant-card-head-title {
  padding: 0px !important;
  border-bottom: 1px solid #ebedf1 !important;
}

.event-avatar-uploader .ant-upload.ant-upload-select-picture-card {
  width: 192px !important;
  height: 170px !important;
  margin-bottom: 0px !important;
  border-radius: 10px !important;
}

.ant-row .ant-form-item {
  margin-bottom: 16px;
}

.events_modal .ant-modal-header {
  border-bottom: 0px !important;
}

.organizer-singup {
  max-width: 420px;
  margin: 0 auto;
  width: 100%;
}

.organizer-singup-logo {
  border-radius: 50%;
}

.signup-btn-org {
  background: #2ba7ca;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  max-width: 327px;
  width: 100%;
  justify-content: center;
  padding: 13px 30px;
  height: 100%;
  position: relative;
  margin-bottom: 16px;
}

.signup-btn-org span img {
  position: absolute;
  left: 35px;
  margin: auto 0;
  bottom: 0;
  top: 0;
}

.organizer-singup p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1b2633;
  margin: 32px 0;
}

.org-login-send-btn {
  background: #8cc73f;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  max-width: 327px;
  width: 100%;
  justify-content: center;
  margin-left: 34%;
  padding: 13px 30px;
  margin-top: 100px;
  height: 100%;
  position: relative;
  margin-bottom: 16px;
}

.org-login-send-btn-1 {
  background: #8cc73f;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  max-width: 327px;
  width: 100%;
  justify-content: center;
  margin-left: 34%;
  padding: 13px 30px;
  margin-top: 100px;
  height: 100%;
  position: relative;
  margin-bottom: 16px;
}

.confirm-btn-org {
  background: #2ba7ca;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  max-width: 327px;
  width: 100%;
  justify-content: center;
  padding: 13px 20px;
  margin-top: 50px;
  height: 100%;
  position: relative;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
}

.another-form-title-org {
  font-size: 22px;
  line-height: 31px;
  margin-bottom: 10px;
}

.other-Account-org {
  background: #f5f6f8;
  padding: 15px 15px;
}

.avtar-org {
  text-align: left;
}

p.user-name-org {
  margin: 0;
  display: inline-block;
  margin-left: 15px;
}

.lable-title {
  margin-bottom: 6px;
  margin-top: 13px;
}

.gender-btn-group {
  text-align: left;
}

.gender-btn {
  padding: 8px 20px;
  width: auto !important;
  height: auto;
  line-height: 18px;
  margin-right: 5px;
}

.create-account-btn {
  background-color: #ebedf1;
  color: #000;
  border: 0;
}

.other-act-btn button.signup-btn-org {
  max-width: 100%;
}

.create-password-lable {
  text-align: left;
}

.password-field {
  text-align: left;
}

.password-radio-org {
  text-align: left;
  margin-bottom: 20px;
}

.create-password-btn {
  justify-content: flex-start;
}

.create-act-fields {
  text-align: left;
}

.form-control div {
  width: 100%;
  max-width: 100%;
}

.ant-select-selection-overflow {
  flex-wrap: nowrap;
  overflow-x: hidden;
}

.form-control .ant-select-selection-overflow-item {
  width: auto;
}

.form-control.allergies-field .ant-select-selector {
  min-height: 38px;
  font-size: 14px;
}

.form-control.select-birth {
  max-width: 330px;
}

button.create-act-btn {
  margin-top: 30px;
}

.switch-org {
  display: flex;
  align-items: center;
}

.switch-org p {
  margin-right: 15px;
}

.switch-org button {
  margin-top: 8px;
}

.socials-media {
  padding-top: 20px;
}

.social-switch-img img {
  margin-right: 10px;
}

.socials-heading {
  margin-bottom: 15px;
}

.otp-for-phone {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 40px;
}

.otp-for-phone input {
  width: 50px !important;
  height: 70px;
  border-radius: 6px;
  background-color: #ebedf1;
  margin: 0 8px;
  border: none;
  outline: none;
}

.enter-otp-text p {
  margin-bottom: 5px;
  margin-top: 10px;
}

.enter-otp-text label {
  font-weight: bold;
  font-size: 14px;
}

.back-btn-otp {
  padding: 30px 0px;
  text-align: right;
  width: 100%;
}

.resend-code-btn {
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
  color: #8cc73f;
}

.forgot-password-org p {
  margin-top: 5px;
}

.text-field-forgotpassword {
  margin-bottom: 70px;
}

.steps-action-org-btn {
  padding-right: 30px;
  padding-bottom: 30px;
}

.setings_active_menu {
  background: #c1baf1 !important;
  opacity: 0.8 !important;
  cursor: pointer;
}

.reward-uploader .ant-upload.ant-upload-select-picture-card {
  height: 80px !important;
  width: 80px !important;
  border-radius: 50% !important;
}

.profile-tabs .ant-tabs-tab-active {
  background-color: #ebedf1 !important;
  border-radius: 10px 10px 0 0 !important;
  color: #000 !important;
  border-bottom: none !important;
}

.profile-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000608 !important;
}

.profile-tabs .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.profile-tabs .ant-tabs-nav-list {
  margin-left: 40px;
}

.upload {
  border-radius: 8px;
  padding: 8px 12px;
  height: auto;
  border-color: #ebedf1;
}

.upload span {
  font-size: 14px !important;
}

.table-layout {
  margin-bottom: 30px;
  margin-top: 20px;
}

.table-layout .ant-table-thead {
  background-color: #f5f6f8;
}

.table-layout tr:last-child {
  border-radius: 20px;
}

.table-layout tr {
  border-bottom: 1px solid rgba(223, 224, 225, 0.3);
}

.parent-profile_header {
  padding: 40px;
  margin-bottom: 10px;
}

.parent-block-notify {
  max-width: 450px;
  width: 100%;
}

.collspan {
  width: 100%;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.label-gray {
  font-size: 14px;
  font-weight: 400;
  color: #9ca5b0;
  display: block;
}

.collspan h4 {
  margin-bottom: 3px;
}

.down-arrow {
  position: absolute;
  right: 0;
  top: 15px;
}

.inner-toggle-swich {
  justify-content: space-between;
}

.payment-switch-div {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.inner-toggle-swich p img {
  margin-right: 10px;
}

.permissions-modules tr td:first-child {
  white-space: nowrap;
}

.radioButton {
  padding: 9px 26px;
  height: 100%;
  border: none !important;
  color: #000229;
  margin-right: 16px;
}

.ant-radio-button .ant-radio-button-checked {
  background-color: #18ff3f !important;
}

.radioButtonSelected {
  padding: 9px 26px;
  height: 100%;
  background-color: #d7d8e9 !important;
  border-radius: 8px;
}

.event-row .ant-row {
  margin: 0 !important;
}

table .ant-table-thead > tr > th {
  background-color: #fff;
}

.sidebar-label {
  size: 14px;
  line-height: 19.5px;
  color: rgb(0 0 0 85%);
  align-items: center;
  padding: 22px 25px;
  cursor: pointer;
}

.Mentors-Proposed-block {
  padding-top: 40px;
  padding-left: 30px;
}

.ant-menu-inline {
  border-right: none;
}

#root {
  /* height: 100%; */
  height: 100vh !important;
}

#root .ant-spin-nested-loading,
#root .ant-spin-container {
  height: 100%;
}

.sidebar-label .anticon-down {
  float: right;
}

ul.sub-menu-list {
  padding: 0 20px;
}

ul.sub-menu-list li {
  height: 30px !important;
  line-height: 30px !important;
}

ul.sub-menu-list li .ant-menu-title-content {
  display: flex;
  align-items: center;
}

ul.sub-menu-list li .dot {
  width: 8px;
  height: 8px;
  background: #0000006b;
  border-radius: 50%;
  margin-right: 10px;
}

.button_organizer {
  background: #2ba7ca;
  border-radius: 10px !important;
  padding: 0px 23px;
  width: 92px;
  height: 38px;
  color: #ffffff;
}

.img_round_circle {
  padding: 5px;
  border-radius: 15px;
  background-color: #ebedf1;
}

.display-none {
  display: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #8cc73f !important;
  border-color: #8cc73f !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #8cc73f !important;
}

.widgets-drawer
  .ant-drawer-body
  ul
  li
  span.ant-checkbox
  span.ant-checkbox-inner {
  border-radius: 4px;
}

.widgets-drawer .ant-divider-horizontal {
  margin: 14px 0;
}

.review-card {
  max-width: 327px;
  margin-bottom: 10px;
}

@media screen and (max-width: 991px) {
  ul.sub-menu-list {
    padding: 0 10px;
  }
}

.mentors-table-header {
  padding: 15px 30px 15px 30px;
  width: 100%;
}

.mentors-page-header {
  padding: 24px 30px 15px 30px;
}

.ant-table-thead > tr > th {
  color: rgb(0, 0, 0);
  font-family: 'Montserrat', sans-serif;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 19px;
}

.organizations_table {
  margin: 0px;
  padding: 0px !important;
}

.organizations_table .ant-table-thead > tr > th {
  background: rgba(235, 237, 241, 0.5);
  padding: 14px 13px;
}

.organizers_table .ant-table-tbody tr td,
.organizations_table .ant-table-tbody tr td {
  padding: 8px 13px;
}

.organizers_table .ant-table-thead > tr > th {
  background: #ebedf1 !important;
  padding: 14px 13px;
}

.family_table .ant-table-thead > tr > th {
  background: #e5e5e5 !important;
}

.status_button {
  border: 1px solid #ebedf1;
  border-radius: 10px;
  height: 37px;
  position: relative;
  padding-right: 35px;
}

.status_button .ant-space.ant-space-horizontal.ant-space-align-center {
  justify-content: flex-start;
  width: 100%;
}

.status_button .ant-space-item:last-child {
  position: absolute;
  right: 10px;
}

.set-status-modal .ant-modal-body .alert_delete_button {
  width: 148px;
}

.autocomplete_searchbox {
  max-width: 519px;
  width: 100%;
  padding-left: 44px;
  height: 36px;
  margin: auto 0;
  background: #f8f8f8;
  border: 1px solid #ebedf1;
  border-radius: 8px;
}

.autocomplete-input-box {
  position: relative;
  margin-right: 30px;
  width: 100%;
}

.autocomplete-input-box .autocomplete-input-group {
  margin-right: 20px;
}

.autocomplete-input-box span.anticon.anticon-search {
  top: 10px;
}

.session-temp-btn {
  background-color: #ebedf1;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 9px 31px;
}

.session-header {
  border-bottom: 1px solid #ebedf1;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 40px;
}

.edit-shedule-btn {
  background-color: rgb(235, 237, 241);
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: none;
  line-height: 14px;
  font-size: 12px;
  font-weight: 600;
}

.session-shedule {
  padding: 8px;
  padding-bottom: 0;
}

.coach_profile_header {
  padding: 40px, 30px, 30px, 24px;
}

.events_card .ant-card-head-title {
  margin-bottom: 0px !important;
  padding: 8px 16px !important;
  border-bottom: 1px solid #ebedf1;
  font-weight: 600;
  color: #000000;
}

.events_card {
  padding: 0px !important;
}

.events_card .ant-card-body {
  padding: 16px 16px;
  border: 1px solid #f0f0f0;
}

.permission-table::-webkit-scrollbar {
  width: 100%;
  height: 8px;
}

.permission-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.permission-table::-webkit-scrollbar-thumb {
  background: #2ba7ca;
  border-radius: 20px;
}

.permission-table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.schedule_fullcalendar_container {
  padding-top: 25px;
  padding-left: 21px;
  background-color: #fff;
}

.session_calendar .ant-table-thead > tr > th {
  background: #ebedf1 !important;
  padding: 8px;
}

.calenderApp_table_cell td {
  padding: 0px !important;
  height: 79px;
  width: 200px;
}

.border_container {
  border-right: 1px solid #ebedf1;
}

.coach_profile_card {
  width: 375px;
  height: 820px;
}

.edit-profile-btns {
  width: 315px;
  height: 48px;
}

.edit-profile-change-pass-btns {
  width: 315px;
  height: 48px;
  margin-bottom: 15px;
  border: #fff;
}

.coach-profile-amt-detail {
  height: 101px;
}

.events-details-card {
  width: 100%;
  margin-bottom: 20px;
}

.credit-price-green {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #a6ca16;
}

.credit-payment-history-pink {
  position: absolute;
  height: 27px;
  right: 0;
  top: calc(50% - 27px 2);
  color: #ff6961;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.credit-payment-history-black-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.credit-payment-history-gray-text {
  display: flex;
  color: #9ca5b0;
}

.payment-history-main {
  justify-content: space-between;
  display: 'flex';
}

.price-card {
  min-height: 78px;
}

.ant-card.ant-card-bordered.price-card {
  border: 1px solid #ebedf1;
  box-shadow: none;
  border-radius: 10px;
  padding: 17px 20px;
  display: flex;
  align-items: center;
}

.ant-card.ant-card-bordered.price-card .ant-card-body {
  width: 100%;
}

.register_child_button-events {
  background: #2ba7ca;
  width: 102px;
  height: 36px;
  color: #fff;
  border-radius: 20px;
  border: 1px solid #2ba7ca;
}

.register_child_button-events:focus,
.register_child_button-events:active {
  background: #2ba7ca;
  color: #fff;
  border: 1px solid #2ba7ca;
}

.register_child_button-events:hover {
  background: #fff !important;
  color: #2ba7ca !important;
}

.checkin_button-events {
  color: #2ba7ca;
  width: 102px;
  height: 36px;
  border-radius: 20px;
  margin-right: 20px;
}

.avatar-name {
  padding-left: 20px;
}

.Organization-header {
  text-align: right;
  top: 5px;
}

.status-title {
  display: inline-block;
  margin-right: 20px;
}

.actionButton-org-profile {
  margin-left: 20px;
  border-radius: 8px !important;
  font-size: 15px;
  font-weight: 500px;
}

.profile-head-action-dropdown button.status_button {
  height: 48px;
}

.org-sync-calendars {
  text-align: center;
}

.text-right {
  text-align: right;
}

.org-today-event-btn {
  width: 100%;
  justify-content: flex-end;
  padding-right: 60px;
}

.event-dropdown-btn {
  position: absolute;
  top: 0;
  right: 10px;
  margin-left: 0;
}

.org-admin-table {
  overflow-x: auto;
}

.org-admin-table table tr th {
  white-space: nowrap;
}

@media screen and (min-width: 1200px) {
  .text-xl-right {
    text-align: right;
  }
}

@media screen and (min-width: 992px) {
  .org-profile-event-cal {
    order: 2;
  }
}

@media screen and (max-width: 1199px) {
  .Organization-header {
    text-align: left;
    padding-top: 10px;
  }

  .actionButton-org-profile {
    width: 155px;
    max-height: 40px;
    min-height: 40px;
  }

  .profile-head-action-dropdown button.status_button {
    height: 40px;
  }

  .profile-tabs .ant-tabs-tab {
    padding: 12px 22px !important;
  }

  .overflow-lg-x {
    overflow-x: auto !important;
  }

  .side-bar.create-event-side-bar {
    flex: 0 0 390px !important;
    max-width: 390px !important;
    min-width: 390px !important;
    width: 390px !important;
  }

  .event-layout .ant-layout-sider-children {
    width: 390px !important;
  }

  .create-new-question {
    padding-left: 20px;
    padding-right: 10px;
    font-size: 14px !important;
  }
}

@media screen and (max-width: 991px) {
  .org-profile-event-cal {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .org-today-event-btn {
    justify-content: flex-start;
  }
}

.tooltip-chart {
  background-color: blue !important;
}

.status-modal-heading {
  display: flex;
  justify-content: center;
}

.status_modal_button {
  position: absolute;
  width: 154px;
  height: 51px;
  background: #2ba7ca;
  border-radius: 10px;
}

.status_btn_main {
  width: '326px';
  justify-content: 'center';
  height: '36px';
}

.skillSet.ant-space {
  display: flex !important;
  flex-wrap: wrap !important;
}

.event-agreement-body {
  max-width: 600px !important;
  width: 100% !important;
}

.event-agreement-body .ant-modal-header {
  border-bottom: 0;
}

.event-agreement-body .ant-modal-body {
  padding: 4px 40px 100px 40px;
  min-height: 470px;
  position: relative;
}

.e-aggremant {
  display: flex;
  margin-bottom: 16px;
}

.e-aggremant .ant-checkbox-wrapper {
  align-self: center;
}

.e-aggremant span.ant-checkbox-inner {
  width: 24px;
  height: 24px;
}

.e-aggremant .ant-checkbox-inner::after {
  top: 9px;
  left: 5px;
  width: 8px;
  height: 12px;
}

.e-aggremant .e-card-icon {
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  line-height: 53px;
  padding: 0;
  text-align: center;
  margin-right: 16px;
  margin-left: 16px;
}

.e-aggremant .e-content {
  padding: 11px 16px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  width: 100%;
}

.e-add-cell {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 30px;
}

button.add-cell-btn {
  border: none;
  color: #2ba7ca;
  font-size: 15px;
  line-height: 23px;
  padding: 14px 10px;
}

.e-app-prev button {
  padding: 14px 40px;
  height: auto;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  font-size: 15px;
}

.e-app-prev button.primary {
  background: #2ba7ca;
  margin-left: 20px;
}

.e-app-prev button.bg-gray {
  background: #ebedf1;
}

.agreement-preview {
  padding-bottom: 24px;
  padding: 0 88px 24px 88px;
}

.agreement-preview .e-card-icon {
  border: none;
  box-shadow: none;
  padding: 0;
}

.event-agreement-p-header h1 {
  text-align: center;
  margin-bottom: 20px !important;
}

.agreement-content-area {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
}

.agreement-content-item {
  padding-right: 30px;
}

.create_session_confirm_modal_details_header {
  padding: 12px 20px !important;
  border-radius: 8px;
  background-color: #e5e5e5 !important;
  display: flex;
  justify-content: space-between;
}

.create_session_confirm_modal_details_container {
  padding: 1rem;
}

.create_session_confirm_modal_details_item {
  display: flex;
  justify-content: space-between;
}

.stepper_next_container {
  display: flex;
  justify-content: space-between;
}

.new-session-model-header {
  position: relative;
}

.new-session-model-header .ant-layout {
  padding: 0;
  padding-top: 20px;
}

.custom-modal-bg section.ant-layout {
  padding-bottom: 90px;
}

.custom-modal-bg .modal_container {
  padding: 20px 30px 90px 30px;
}

.session-model-next-back-btn {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 30px;
  width: auto;
}

.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}

.request-stats table tr th,
.request-stats table tr td {
  border-bottom: none;
  padding: 10px 10px 10px 40px;
  font-size: 14px;
}

.request-stats table tr td:hover {
  background-color: transparent;
}

.img-upload {
  background-color: red !important;
}

.ant-upload-list {
  display: flex;
}

.antd-img-upload {
  overflow: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.location-container {
  width: 343px !important;
}

.map-closing-icon {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  z-index: 1;
  right: 10px;
}

.audio-closing-icon {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  z-index: 1;
  right: 10px;
}

.video-closing-icon {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  z-index: 1;
  right: 10px;
}

.image-closing-icon {
  display: flex;
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 100px;
}

.video-js .vjs-volume-menu-button {
  display: none;
}

.video-js.vjs-fullscreen .vjs-volume-menu-button {
  display: block;
}

.newsfeed-right-block {
  padding: 40px;
  padding-top: 0px;
}

.table-head {
  font-weight: 600;
}

.table-action-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  position: absolute;
  height: 37px;
  right: 40px;
  top: -19px;
  z-index: 1;
}

.new-organizations-export-btn.table-action-btn {
  position: unset;
  top: 0px;
}

.play-pause-btn {
  width: 36px;
  height: 36px;
  line-height: 36px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: transparent;
  border: none;
  cursor: pointer;
}

.play-pause-btn:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  outline: none;
}

.play-pause-btn img {
  display: none;
  position: relative;
  z-index: 99;
  top: -1px;
}

.play-pause-btn.play .playIcon {
  display: inline-block;
}

.play-pause-btn.pause .pauseIcon {
  display: inline-block;
}

@media screen and (max-width: 780px) {
  .table-action-btn {
    right: 20px !important;
  }

  .btn-new {
    width: 114px !important;
  }

  .btn-export {
    width: 114px !important;
  }
}

.btn-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 32px;
  gap: 10px;

  width: 154px;
  height: 37px;
  background: #2ba7ca;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.btn-new-activity {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  color: #ffff;
  width: 154px;
  height: 41px;
  background: #2ba7ca;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.btn-new-activity:hover {
  color: #2ba7ca !important;
}
.btn-new:hover {
  color: #2ba7ca !important;
}

.btn-export {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 32px;
  gap: 10px;

  width: 142px;
  height: 37px;
  background: #ebedf1;
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.main-bg-custom-chat {
  border-right: 1px solid #ebedf1;
  position: relative;
  height: calc(100vh - 196px);
  padding: 0 40px 100px 40px;
  flex: 1;
}

.custom-chat-textarea {
  resize: none;
  height: 50px !important;
  line-height: 28px !important;
  border: 1px solid #dfe0e1 !important;
  border-radius: 12px;
  padding: 8px 45px 8px 16px;
  outline: none !important;
  box-shadow: none !important;
}

.custom-text-area {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 0;
  background-color: #fff;
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 20px;
}

.contact-list-chat {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 20px;
}

.contact-list-chat .ant-comment,
.contact-list-chat .ant-comment .ant-comment-content {
  position: unset;
}

.contact-list-chat .ant-list.ant-list-split {
  height: 100%;
  overflow-y: auto;
}

.contact-list-chat .ant-spin-nested-loading {
  height: auto !important;
}

.custom-text-area span.anticon.anticon-send {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  text-align: center;
  align-items: center;
  margin: auto 0;
  width: 50px;
  justify-content: center;
}

.file-attechment {
  display: inline-block;
}

.text-input-group {
  width: 100%;
}

.chat-list.ant-list-split .ant-list-item {
  border-bottom: 1px solid #898a994d;
  padding: 10px 20px;
}

.note-add {
  width: 130px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
}

.details-tag {
  background-color: #ebedf1;
  padding: 8px 16px;
}

.stepper-section-header {
  padding: 10px 40px 0px;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  position: relative;
}

.guestNumber .ant-slider-rail {
  background-color: gray;
}

.event-create-container {
  display: flex;
  justify-content: space-evenly;
  padding: 15px 150px;
}

.event-layout .ant-layout-sider-children {
  width: 506px;
}

.venue-card {
  padding: 20px 0px !important;
  justify-content: center;
  margin: 20px 0px;
}

.venue-card .ant-card.ant-card-bordered {
  border: none !important;
  box-shadow: none !important;
}

.template-card {
  padding: 10px 10px !important;
  border: 1px solid #07839c;
  justify-content: center;
  margin: 20px 0px;
}

.template-card .ant-card.ant-card-bordered {
  border: none !important;
  box-shadow: none !important;
}

.route_button-events {
  width: 100%;
  margin: 10px;
  background: #eeeff1;
  color: #000000;
  height: 36px;
  border-radius: 20px;
}

.venue_button-events {
  width: 100%;
  margin: 10px;
  background: #2ba7ca;
  height: 36px;
  color: #fff;
  border-radius: 20px;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.venue-more-action {
  border-radius: 10px;
  margin-left: 10px;
  border: none;
  height: 20px;
}

.venue_cost_text {
  color: #3274b6;
}

.venue-tags {
  border: none;
}

.message-list-conversation {
  height: 100%;
  overflow-y: auto;
  padding-top: 20px;
}

aside.emoji-picker-react {
  position: absolute;
  left: 0;
  bottom: 50px;
  height: 260px;
  border-radius: 15px;
}

.message-list-conversation .ant-comment {
  display: flex;
  justify-content: flex-end;
}

.message-list-conversation .ant-comment-inner {
  background: #f7941d;
  padding: 8px 12px 25px 12px;
  border-radius: 8px 8px 0px 8px;
  max-width: 80%;
  width: auto;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
  min-width: 150px;
}

.message-list-conversation
  .ant-comment-inner
  span.ant-comment-content-author-name {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #fff;
}

.message-list-conversation .ant-comment-content-detail p {
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  word-break: break-all;
}

.right-side-contact-list {
  display: flex;
  flex-direction: row;
}

.contact-list {
  min-width: 309px;
  max-width: 309px;
}

.message-list-conversation
  .ant-comment-inner
  span.ant-comment-content-author-time {
  position: absolute;
  right: 0;
  bottom: -17px;
  padding-right: 0;
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #fff;
}

@media screen and (max-width: 991px) {
  .contact-list {
    min-width: 240px;
    max-width: 240px;
  }
}

.headerSchedule {
  position: absolute;
  left: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #9ca5b0;
  display: block;
  margin: 10px;
}

.new-tmp-button {
  width: 191px;
  margin: 10px;
  background: #eeeff1;
  color: #000000;
  height: 51px;
  border-radius: 10px;
}

.use-tmp-button {
  width: 185px;
  margin: 10px;
  background: #2ba7ca;
  height: 51px;
  color: #fff;
  border-radius: 10px;
}

.create-event-side-bar .ant-steps-item-active {
  background: #ffffff;
  box-shadow: 0px 12px 24px rgb(68 68 68 10%);
  border-radius: 8px;
}

.create-event-side-bar
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #9ca5b0;
  font-size: 14px;
  line-height: 24px;
}

.create-event-side-bar .ant-steps-item-description {
  padding-bottom: 0px !important;
  font-size: 18px;
  line-height: 28px;
  color: #000 !important;
}

.create-event-side-bar .ant-steps-item-tail {
  display: none !important;
}

.create-event-main-content-wapper {
  margin: 30px auto 0 auto;
  padding: 0 100px;
  position: relative;
  padding-bottom: 60px !important;
}

.event-step-cont-btn {
  position: absolute;
  left: 100px;
  right: 100px;
  bottom: 0;
}

.create-event-main-content-box {
  max-width: 520px;
  width: 100%;
}

.calender-event-label {
  padding: 8px;
  width: 100%;
}

.step-2-parent .calender-event-label {
  border-top: 1px solid #ebedf1;
  padding-top: 25px;
  padding-bottom: 5px;
  margin-top: 8px;
  text-align: center;
}

.event-schedule-step2 {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.create-event-main-content-box h2 {
  margin-bottom: 21px !important;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: #000000;
}

.change-template-btn {
  width: 327px;
  height: 52px;
  border-radius: 10px;
  background-color: #eeeff1;
  border: 1px solid #eeeff1;
}

.save-for-events-btn {
  width: 327px;
  height: 52px;
  border-radius: 10px;
  background-color: #2ba7ca;
  color: #fff;
}

.new-tmp-text {
  margin: auto;
  width: 353px;
  font-size: 18px;
  text-align: center;
}

.estimated-summ-container {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 12px;
}

.event-activity-item-step-2 {
  color: #000229;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.time-text-step2 {
  color: #9ca5b0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: block;
}

.time-text-steps-right {
  text-align: right;
  line-height: 15px;
  font-size: 12px;
  font-weight: 400;
}

.additional-setting-right {
  display: flex;
  gap: 12px;
}

.additional-setting-right span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.additional-setting-right span.small-gray-details {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  opacity: 0.5;
}

.additional-setting-session-col {
  padding-left: 38px;
}

.step-5-heading {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
}

.cover-img-step-5 {
  text-align: center;
}

.cover-img-step-5 .ant-upload-picture-card-wrapper .ant-upload-list {
  width: 100%;
  display: unset;
}

.cover-img-step-5 .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 145px;
  line-height: 100px;
  margin-right: 0;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #ebedf1;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.cover-img-step-5 .ant-upload.ant-upload-select-picture-card span.ant-upload {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
}

img.step-cover-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-acover-pic {
  padding: 9px 20px;
  font-size: 12px;
  line-height: 15px;
  color: #000;
  border-radius: 20px;
  background-color: #fff;
}

.upload-acover-pic img {
  margin-right: 10px;
}

.admission-ticket-card-row {
  display: flex;
  gap: 0px 25px;
  flex-wrap: wrap;
}

.admission-ticket-card-row .admission-ticket-card {
  width: 100%;
  position: relative;
  padding-left: 40px;
  min-height: 143px;
  min-width: 46%;
  border-radius: 10px;
  background: rgba(232, 118, 159, 0.4);
  box-shadow: none;
  border: none;
  margin-top: 20px;
  overflow: hidden;
  flex-basis: 33%;
  width: 46%;
}

.admission-ticket-card-row .admission-ticket-card .ant-card-body:after {
  position: absolute;
  content: '';
  width: 200px;
  height: 330px;
  background: linear-gradient(
    206.73deg,
    #ffffff 22.12%,
    rgba(255, 255, 255, 0) 92.68%
  );
  mix-blend-mode: lighten;
  opacity: 0.2;
  right: -110px;
  top: 0;
  transform: rotate(28deg);
}

.admission-ticket-card h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000;
  margin-bottom: 13px !important;
}

span.ticket-span-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  display: block;
  margin-bottom: 20px;
}

.admission-ticket-card button {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  border-radius: 50px;
  color: #fff;
  background-color: #000000;
  border: none;
  outline: none;
}

.admission-ticket-card button span {
  color: #fff;
}

.admission-ticket-card button:hover span {
  color: #000 !important;
}

.admission-ticket-card-row .admission-ticket-card:before,
.admission-ticket-card-row .admission-ticket-card:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}

.admission-ticket-card-row .admission-ticket-card:before {
  right: 0;
  border-radius: 20px 0px 0px 20px;
}

.admission-ticket-card-row .admission-ticket-card:after {
  left: 0;
  border-radius: 0 20px 20px 0;
}

.weather-temprature {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #fefeff;
  position: relative;
  display: inline-block;
}

.weather-temprature:after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  right: -2px;
  top: -2px;
}

.weather-days-forecast {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #fefeff;
  opacity: 0.8;
  white-space: nowrap;
}

.small-temprature {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 700;
  color: #fefeff;
  position: relative;
  display: inline-block;
}

.small-temprature:after {
  position: absolute;
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  border: 1px solid #fff;
  right: -2px;
  top: -2px;
}

.weather-forcast-date {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #fefeff;
  opacity: 0.5;
  margin-bottom: 10px;
  display: block;
}

.weather-forecast-weekly-days {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.weather-forecast-weekly-days-list {
  padding: 10px 10px;
  border-radius: 50px;
  margin: 0 5px;
  position: relative;
}

.weekly-img {
  display: block;
}

li.weather-forecast-weekly-days-list:after {
  width: 100%;
  height: 100%;
  position: absolute;
  content: '';
  background: #fefeff;
  opacity: 0.08;
  border-radius: 80px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
  display: none;
}

.weather-forecast-weekly-days-list:hover::after {
  display: block;
}

.confirmation-modal .model-header {
  border-bottom: none;
}

.confirmation-modal .model-header h4 {
  margin-bottom: 0 !important;
}

.confirmation-modal.organization_model .ant-layout {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.general-detail-devider {
  background: #ebedf1;
  mix-blend-mode: normal;
  border-radius: 8px;
  padding: 12px 20px;
  justify-content: flex-start;
  margin: 0 !important;
  color: #9ca5b0;
}

.general-detail-devider:after,
.general-detail-devider:before {
  display: none;
}

.general-detail-devider span {
  padding: 0;
}

.ant-layout.confirmation-layout {
  padding: 10px 10px;
}

.preview-temp-footer {
  width: 50%;
  margin: auto;
  margin-top: 30px;
}

.preview-temp-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  height: auto;
  border: 1px solid #ebedf1;
  box-shadow: none;
}

.icon-temp-preview {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
}

.new-family-txt {
  color: #2ba7ca !important;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 23px !important;
}

.activities-details-modal .ant-modal-body {
  padding: 40px 40px;
}

.activities-details-modal .model-header {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 20px;
}

.activities-details-modal .model-header h4 {
  font-weight: 600;
  font-size: 24px;
  color: #000;
  line-height: 36px;
}

.activities-details-leftside {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}

.activities-details-left-chiled,
.activities-details-right-chiled {
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}

.activities-details-left-chiled {
  padding-right: 20px;
}

.activities-details-right-chiled {
  padding-left: 20px;
}

.activities-details-left-chiled h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin-bottom: 16px !important;
}

.add-activity-btn {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-activity-btn a {
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #2ba7ca;
  display: block;
}

.add-activity-btn button {
  font-size: 15px;
  line-height: 23px;
  padding: 14px 40px;
  height: auto;
  border-radius: 8px;
  border: 1px solid #2ba7ca;
  background-color: #2ba7ca;
  color: #ffffff;
}

.supplies-details {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  color: #000;
  align-items: center;
}

.supplies-details label.ant-checkbox-wrapper {
  margin-right: 14px;
  line-height: 16px;
}

.activities-group-list ul li {
  display: flex;
  align-items: center;
  gap: 22px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  margin-bottom: 16px;
}

.activities-group-list ul li span {
  flex: 1;
  border: 1px solid #f3f4f5;
  border-radius: 13px;
  padding: 11px 16px 11px 16px;
}

.activities-group-list ul li img {
  width: 23px;
}

.assign-tasks-list {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.assign-tasks-list span {
  flex: 1;
  background: #fafafb;
  border: 1px solid #ebedf1;
  border-radius: 13px;
  padding: 11px 16px;
  gap: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.assign-tasks-list span img {
  width: 17px;
  margin-top: 5px;
}

.email-template-confirmation-email {
  background: #eeeff1;
  border-radius: 10px;
  padding: 10px 20px 20px;
  height: 100%;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
}

.email-template-child {
  width: 100%;
  margin-bottom: 30px;
}

.email-social-and-copyright {
  width: 100%;
}

.email-social-and-copyright {
  text-align: center;
}

.email-social-media {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.menter-a-request-model-header {
  border-bottom: 1px solid #ebedf1;
}

ul.discount-code-details li {
  display: flex;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

ul.discount-code-details li span {
  width: 50%;
  font-size: 14px;
  line-height: 17px;
  color: rgb(0, 0, 0, 0.4);
  font-weight: 400;
}

ul.discount-code-details li span b {
  font-weight: 500;
  color: #000;
}

.list-discount-used-users ul.ant-list-items li.ant-list-item {
  padding: 0;
  border: none;
  margin-bottom: 20px;
}

.ant-space.ant-space-horizontal.ant-space-align-center.month-btn {
  flex-wrap: wrap;
  width: 305px;
}

.ant-space.ant-space-horizontal.ant-space-align-center.year-btn {
  flex-wrap: wrap;
  width: 373px;
}

.ant-modal.payment-frequency-modal .ant-modal-body {
  padding: 16px;
}

.ant-select.frequency-days.ant-select-single.ant-select-show-arrow
  .ant-select-selector
  span,
.ant-select.frequency-days.ant-select-single.ant-select-show-arrow
  span.ant-select-selection-placeholder {
  height: 20px;
  top: 12px;
  line-height: 20px;
  display: block;
  position: absolute;
}

.ant-space.ant-space-horizontal.ant-space-align-center.select-week-days
  .ant-space-item
  button.ant-btn.ant-btn-default {
  width: 100% !important;
  padding: 0;
}

.ant-space.ant-space-horizontal.ant-space-align-center.select-week-days
  .ant-space-item {
  width: 100%;
}

.ant-space.ant-space-horizontal.ant-space-align-center.select-week-days {
  display: flex;
}

.ant-space.ant-space-horizontal.ant-space-align-center
  .ant-space-item
  .week-name-btn {
  background-color: #2ba7ca;
  color: #fff;
}

.ant-space.ant-space-horizontal.ant-space-align-center
  .ant-space-item
  .week-name-btn
  span {
  color: #fff;
}

.payment-frequency-select-button {
  width: 100%;
  text-align: left;
  border: 1px solid #eeeff1;
  border-radius: 12px;
  padding: 13px 16px;
  height: auto;
  position: relative;
}

.payment-frequency-select-button:after {
  position: absolute;
  content: '>';
  right: 10px;
  top: 13px;
  font-size: 25px;
  color: #000;
  line-height: 15px;
}

.ant-dropdown.ant-dropdown-placement-topLeft.ant-dropdown-hidden {
  min-width: 375px !important;
  max-width: 375px;
}

.form-control-field {
  height: 50px;
  font-size: 14px;
  font-weight: 400;
}

.form-control-field .ant-select-selector span {
  font-size: 14px;
  align-items: center;
  font-weight: 400;
}

.login-with-email {
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 21px !important;
  display: block;
}

.dont-have-and-account {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #000;
  margin-bottom: 28px;
}

.dont-have-and-account a {
  font-weight: 600;
  color: #2ba7ca;
}

.analytics-page-container {
  height: 100% !important;
}

.organizers_table table tr th.ant-table-selection-column,
.organizers_table table tr td.ant-table-selection-column,
.organizations_table table tr th.ant-table-selection-column,
.organizations_table table tr td.ant-table-selection-column {
  padding-left: 20px;
}

li.ant-pagination-next button.ant-pagination-item-link {
  border-radius: 8px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 8px;
}

.orgn-action-btn {
  width: 100%;
  gap: 36px !important;
}

.orgn-tabs .ant-tabs-nav-wrap {
  padding-left: 30px;
}

.organizations-table-onselect-btn {
  width: 100%;
  justify-content: flex-end;
}

.set-status-modal .ant-modal-body {
  padding: 40px 56px;
}

.custom-select-field {
  height: 38px;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  color: #bcbec0;
}

.custom-select-field span.ant-select-selection-placeholder {
  line-height: 38px !important;
  font-size: 14px !important;
}

.select-pleasholder-black span.ant-select-selection-placeholder {
  color: #000 !important;
}

.select-pleasholder-black span svg {
  color: #000 !important;
}

.custom-select-field input#rc_select_2 {
  height: 38px;
}

.custom-select-field input#rc_select_2 {
  height: 38px;
}

.organization_model .ant-steps-item {
  padding: 0;
}

.organization_model .ant-steps-item .ant-steps-item-icon {
  width: auto;
  height: 16px;
}

.organization_model .ant-steps-item-content {
  vertical-align: middle;
}

.organization_model .ant-steps-item .ant-steps-item-icon span.ant-steps-icon {
  width: 16px;
  height: 16px;
  position: relative;
}

.location-map-modal .secondary.pac-target-input {
  top: 80px !important;
}

.drag-pin-to-choose-location {
  margin: 15px 0 30px !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #9ca5b0;
}

button.confirm-location-btn {
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #ffffff;
  border-radius: 8px;
  padding: 14px 40px;
  height: auto;
}

.location-map-modal .ant-modal-header {
  border-bottom: 0 !important;
  padding-bottom: 0;
}

.custom-common-field {
  height: 50px;
  margin-bottom: 13px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}

.custom-common-field .ant-select-selection-item {
  align-items: center;
}

.gender-radio-button {
  border-radius: 50px !important;
  padding: 4px 25px !important;
}

.profile-back-menu {
  margin: 0 !important;
  height: auto !important;
}

.profile-back-menu span {
  height: auto !important;
  font-size: 15px !important;
  line-height: 22px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.profile-back-menu span svg {
  margin-right: 18px;
  font-size: 20px;
  vertical-align: top;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  border-radius: 8px;
}

.ant-pagination-item-active {
  border-color: #d9d9d9;
  border-radius: 8px;
}

.ant-pagination-item-active a {
  color: #000;
}

.ant-pagination-item {
  border-radius: 8px;
}

.custom-select-field span.ant-select-selection-item {
  line-height: 34px !important;
  color: #9ca5b0;
}

.custom-select-field.black span.ant-select-selection-item {
  color: #000 !important;
}

.weight-500 {
  font-weight: 500;
}

.activeMenu .ant-space-item h5 {
  color: #fff;
}

.activeMenu .ant-space-item img {
  filter: invert(1);
}

.side-bar .ant-menu-item-group-title {
  margin-left: 27px;
  margin-top: 20px;
  padding-bottom: 0;
  font-size: 13px;
  line-height: 20px;
  color: rgba(156, 165, 176, 1);
  font-weight: 600;
}

.side-bar li.ant-menu-item.ant-menu-item-only-child {
  margin: 5px auto;
  width: calc(100% - 48px);
}

.organizations_table .ant-table-pagination.ant-pagination {
  padding: 0 20px;
}

.review-card {
  width: 100%;
}

.ant-rate li.ant-rate-star {
  margin-right: 2px;
  margin-bottom: 5px;
  margin-top: 9px;
}

li.ant-rate-star span.anticon.anticon-star {
  font-size: 14px;
}

li.ant-rate-star.ant-rate-star-full span.anticon.anticon-star {
  color: rgba(242, 201, 76, 1);
}

.review-card-main-bg {
  padding-right: 5px;
}

.review-card-main-bg .ant-card.ant-card-bordered {
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #eeeff1;
  padding: 16px 40px;
}

.review-card-main-bg .ant-card.ant-card-bordered.review-progress-card {
  padding-top: 22px;
  padding-bottom: 22px;
  margin-bottom: 20px;
}

img.delete-icon-reviews {
  width: 21px;
}

.ant-progress-inner {
  border-radius: 0;
}

.ant-progress-bg {
  border-radius: 0;
}

.reviews-progress-bar {
  margin-bottom: 10px;
  max-width: 363px;
}

.reviews-progress-bar span {
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  color: #000;
  margin-right: 12px;
  white-space: nowrap;
}

.reviews-progress-bar .ant-progress-outer {
  width: calc(100% - 5px);
}

.reviews-progress-bar span.ant-progress-text {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0;
}

.delete-modal .ant-modal-body {
  padding: 40px;
}

.organization_model .ant-list-item {
  padding: 16px 0;
}

span.access-icon-box {
  margin-right: 15px;
}

li.ant-list-item.accesslecel-list-items:last-child {
  border-bottom: 1px solid #898a994d;
  margin-bottom: 75px;
}

.ant-modal.organization_model label.ant-radio-wrapper {
  margin-right: 0;
}

li.ant-list-item.accesslecel-list-items:first-child {
  padding-top: 0;
}

li.ant-list-item.assignorganization-list-items {
  padding: 8px 0;
}

.orgnz-profile-tab-organizations-bg {
  height: 100% !important;
  width: 100% !important;
  background-color: #fff !important;
  padding-top: 10px;
}

th.ant-table-cell.ant-table-selection-column {
  padding-left: 20px;
}

td.ant-table-cell.ant-table-selection-column {
  padding-left: 20px;
}

.left-right-space-element th.ant-table-cell:last-child,
.left-right-space-element td.ant-table-cell:last-child {
  padding-right: 40px;
}

.pagination .ant-table-pagination-right {
  padding-right: 30px;
  padding-left: 30px;
}

.manager-drop-btn {
  height: 37px;
  border-radius: 8px !important;
  font-weight: 500;
}

.skill_tags {
  border: 1px solid #d9d9d9;
  padding: 8px 20px;
  margin-right: 0;
  height: auto;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
}

.tutor-heading {
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 0;
}

p.tutor-disc {
  font-size: 16px;
  color: #000000;
  line-height: 24px;
}

.activity-heading {
  font-size: 18px;
  margin-bottom: 8px !important;
  color: #000000;
  line-height: 27px;
}

.activity-date-item {
  font-size: 14px;
  color: #9ca5b0;
  line-height: 21px;
}

.weekly-schedule-list {
  padding-top: 16px;
  padding-bottom: 16px;
}

.delete-btn-img {
  width: 20px;
}

.session-dash h3 {
  font-size: 16px;
  line-height: 20px;
}

.session-dash h4 {
  font-size: 24px;
  line-height: 29px;
}

.sessions-data-table {
  width: 100%;
  overflow-x: auto;
}

.mentors-table-header .ant-space-item:last-child {
  flex: 1;
}

.input-label.secure-password-label {
  font-size: 14px !important;
  line-height: 24px;
  font-weight: 400;
}

.levels-form .ant-row .ant-form-item {
  margin-bottom: 30px;
}

.ant-form.ant-form-vertical.levels-form label {
  font-weight: 500;
  color: #000229;
  margin-bottom: 10px;
}

.levels-form .ant-slider-step {
  height: 1px;
  position: relative;
}

.levels-form .ant-slider-step::before {
  position: absolute;
  content: '0';
  left: 0;
  bottom: -25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000229;
}

.levels-form .ant-slider-step::after {
  position: absolute;
  content: '10';
  right: 0;
  bottom: -25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000229;
}

.levels-form .ant-slider-rail {
  height: 1px;
  background-color: #000229;
  opacity: 0.3;
  position: relative;
}

.levels-form .ant-slider-rail:after {
  position: absolute;
  content: '';
  height: 8px;
  width: 1px;
  background-color: #000229;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.levels-form .ant-slider-rail:before {
  position: absolute;
  content: '';
  height: 8px;
  width: 1px;
  background-color: #000229;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.levels-form .ant-slider-track {
  height: 1px;
  margin-top: -1px;
  position: relative;
}

.levels-form .ant-slider-track:before {
  position: absolute;
  content: '';
  height: 8px;
  width: 1px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ant-slider.social-lavel .ant-slider-track {
  background: #0091ff;
}

.ant-slider.physical-lavel .ant-slider-track {
  background: #32c5ff;
}

.ant-slider.cognitive-lavel .ant-slider-track {
  background: #44d7b6;
}

.ant-slider.emotional-management-lavel .ant-slider-track {
  background: #96edcc;
}

.ant-slider.spiritual-lavel .ant-slider-track {
  background: #f96e3b;
}

.ant-slider.social-lavel .ant-slider-track::before {
  background: #0091ff;
}

.ant-slider.physical-lavel .ant-slider-track::before {
  background: #32c5ff;
}

.ant-slider.cognitive-lavel .ant-slider-track::before {
  background: #44d7b6;
}

.ant-slider.emotional-management-lavel .ant-slider-track::before {
  background: #96edcc;
}

.ant-slider.spiritual-lavel .ant-slider-track::before {
  background: #f96e3b;
}

.ant-form.levels-form .ant-slider .ant-slider-handle {
  border: none;
  box-shadow:
    0px 3px 3px rgb(0 0 0 5%),
    0px 1px 1px 2px rgb(0 0 0 5%),
    0px 3px 4px 2px rgb(0 0 0 8%);
  width: 21px;
  height: 21px;
  margin-top: -12px;
}

.levels-count {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000229;
  background: #eeeff1;
  border-radius: 8px;
  min-width: 58px;
  padding: 4px 0;
  display: inline-block;
  text-align: center;
}

.page-content.events-page-content {
  margin-top: 20px;
}

.ant-card.ant-card-bordered.events_card .ant-card-head {
  min-height: auto;
  border: 1px solid #f0f0f0;
  border-bottom: 0;
  border-radius: 10px 10px 0px 0;
}

.events_card .ant-card-body .ant-image {
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
}

.events_card .ant-card-body .ant-image img.ant-image-img {
  display: block;
  height: 100%;
  object-fit: cover;
}

.ant-card.ant-card-bordered.events_card {
  border-radius: 0;
  box-shadow: none;
  border: none;
}

.event-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.calender-action-btn {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  color: rgba(0, 2, 41, 1);
}

.calender-action-btn:hover {
  background-color: #ebedf1;
  border-color: #ebedf1;
  color: rgba(0, 2, 41, 0.3);
}

.event-side-calender .ant-picker-date-panel .ant-picker-content th {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  padding-bottom: 15px;
}

.mycalender.event-side-calender
  .ant-picker-calendar
  .ant-picker-panel
  .ant-picker-body {
  padding: 0;
}

.your-custom-class span.anticon.anticon-search.ant-select-suffix {
  display: none !important;
}

.your-custom-class span.ant-select-selection-item {
  align-items: center;
}

.your-custom-class .ant-select-selector {
  padding-left: 0 !important;
}

.password-login-input {
  position: relative;
}

img.passwordview-icon {
  position: absolute;
  right: 17px;
  top: 16px;
}

.password-login-input span.ant-input-affix-wrapper.ant-input-password:hover {
  background: transparent;
}

.password-login-input .ant-input-password-icon svg {
  position: relative;
  z-index: 1;
  opacity: 0;
}

.password-field .ant-form-item-control-input {
  border: 1px solid #ebedf1;
  border-radius: 14px;
}

span.anticon.anticon-eye-invisible.ant-input-password-icon:after {
  position: absolute;
  content: '';
  height: 16px;
  width: 1px;
  background: #000;
  transform: rotate(38deg);
  right: 24px;
  top: 10px;
}

.password-login-input .ant-form-item-control-input-content span {
  background-color: transparent !important;
}

.ant-steps-item.stepper.event-stepper {
  padding-left: 16px;
}

.ant-steps-item.stepper.event-stepper .ant-steps-item-icon {
  position: relative;
  width: 20px;
  height: 20px;
  top: 5px;
  margin-right: 12px;
}

.ant-steps-item.stepper.event-stepper .ant-steps-item-icon:after {
  left: 0;
  top: 0;
  transform: translateY(0px);
  font-size: 20px;
  width: 20px;
  height: 20px;
}

h2.create-event-sidebar-heading {
  font-size: 32px;
  line-height: 39px;
  font-weight: 600;
  margin-bottom: 16px !important;
  color: #000;
}

span.mb-20.complete-all-steps {
  line-height: 21px;
  color: #000;
}

.ant-form-item-control-input-content
  input[type='number']::-webkit-inner-spin-button,
.ant-form-item-control-input-content
  input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  display: none;
}

label.ant-checkbox-wrapper.kids-listing-auth-adults {
  margin: 0;
}

.number-of-staff-slide .ant-slider-handle {
  width: 24px;
  height: 24px;
  top: 0px;
  border-color: #8cc73f;
  background-color: #8cc73f;
  transform: translateX(-24%) !important;
  margin-right: 10px;
}

.ant-btn.ant-btn-primary:hover {
  color: #2ba7ca !important;
  background-color: #fff !important;
  border-color: #2ba7ca !important;
}

.ant-btn.ant-btn-primary:hover span {
  color: #2ba7ca !important;
}

.ant-input-number-out-of-range input {
  color: #000000;
}

.number-of-staff-slide .ant-slider-rail:after {
  position: absolute;
  content: '50';
  right: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #8e93a3;
  bottom: -30px;
}

.number-of-staff-slide .ant-slider-rail:before {
  position: absolute;
  content: '0';
  left: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #8e93a3;
  bottom: -30px;
}

.add-more-fees-modal .ant-modal-body {
  padding: 40px 40px;
}

.fees-modal-heading {
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.custom-input-num-field {
  background: transparent;
  height: 50px;
}

.custom-input-num-field input {
  height: 48px;
}

.custom-input-num-field.text-end input {
  text-align: right;
}

.price-range-slider .ant-slider-handle {
  width: 28px;
  height: 28px;
  margin-top: -12px;
  background-color: #8cc73f;
  border-color: #fff !important;
  transform: translateX(-75%) !important;
}

.price-range-slider .ant-slider-track {
  background-color: #8cc73f !important;
}

.small-custom-switch-btn {
  height: 20px;
  min-width: 34px;
  width: 34px;
  background: #ebedf1;
}

.small-custom-switch-btn.ant-switch:focus {
  box-shadow: none;
}

.small-custom-switch-btn .ant-switch-handle {
  width: 16px;
  height: 16px;
}

.small-custom-switch-btn .ant-switch-handle:before {
  border-radius: 50%;
  width: 100%;
}

.small-custom-switch-btn.ant-switch-checked {
  background: #8cc73f;
}

.small-custom-switch-btn.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 16px - 2px);
}

.custom-switch-btn {
  height: 30px;
  width: 51px;
  background: #ebedf1;
}

.custom-switch-btn.ant-switch:focus {
  box-shadow: none;
}

.custom-switch-btn .ant-switch-handle {
  width: 26px;
  height: 26px;
}

.custom-switch-btn .ant-switch-handle:before {
  border-radius: 50%;
  width: 100%;
}

.custom-switch-btn.ant-switch-checked {
  background: #8cc73f;
}

.custom-switch-btn.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 26px - 2px);
}

.number-of-staff-slide.unlimited-limit .ant-slider-rail:after {
  content: 'Unlimited';
}

.custom-input-num-field .ant-input-number-handler-wrap {
  display: none;
}

.color-selector-custom-cls span {
  margin-right: 16px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

input,
textarea {
  border-color: #eeeff1 !important;
}

.ant-slider-handle:focus {
  box-shadow: none !important;
}

.num-field-arrow-null .ant-input-number-handler-wrap {
  display: none;
}

.custom-input-text-center input {
  text-align: center;
}

.discount-code-details-modal .ant-modal-body {
  padding: 0;
}

.discountcode-details-modal-header {
  padding: 40px 40px 0;
}

.used-users-list {
  padding: 0px 30px 30px;
}

.temp-model-step2 .ant-modal-close {
  display: none;
}

.temp-model-step2 .ant-modal-body {
  padding: 40px 71px;
}

.overflow.discount-code-details-modal .ant-layout {
  border-radius: 0 0 20px 20px;
}

.gay-searchbar input.search-input-group {
  background: #f8f8f8;
  border-color: #f8f8f8 !important;
}

.select-activity-tab .ant-tabs-nav-wrap {
  padding-left: 45px;
  padding-right: 45px;
}

.custom-checkbox .ant-checkbox-inner {
  background-color: #eeeff1;
  border-color: #eeeff1;
  border-radius: 4px;
  width: 24px;
  height: 24px;
}

.mastertable-view thead.ant-table-thead th {
  background-color: #ebedf1;
}

.allergies-icon-item {
  box-shadow: 0px 1px 8px rgba(99, 103, 103, 0.2);
  border-radius: 100px;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 28px;
}

.tab-ml-0 .ant-tabs-tab {
  margin-left: 0;
}

.kids-list {
  max-height: 160px;
  overflow: hidden;
  overflow-y: auto;
}

.kids-list::-webkit-scrollbar {
  width: 4px;
}

.kids-list::-webkit-scrollbar-thumb {
  background: #2ba7ca;
}

.required-field-star::before {
  content: '*';
  position: absolute;
  left: 0;
  top: 0;
  color: #e32;
  font-size: 16px;
  font-weight: 600;
}

.phone-number-field .ant-select-selector {
  border: none !important;
  cursor: pointer !important;
}

.modal-h-element {
  height: 396px;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 10px;
}

.modal-h-element::-webkit-scrollbar {
  width: 15px;
}

.add-activity-modal-h {
  height: 539px;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 10px;
}

.add-activity-modal-h::-webkit-scrollbar {
  width: 15px;
}

.add-activity-modal-h::-webkit-scrollbar-thumb {
  background: #2ba7ca;
  border-left: 10px solid #fff;
}

.modal-h-element::-webkit-scrollbar-thumb {
  background: #2ba7ca;
  border-left: 10px solid #fff;
}

.calendar-b-0 .mycalender tbody {
  border: 0;
}

.activity-levels-graph canvas {
  width: 100% !important;
}

li.ant-pagination-total-text {
  flex: 1;
  font-weight: 500;
  color: #000;
}

.select-family-field .ant-select-selector {
  height: 38px !important;
  line-height: 38px !important;
}

.select-family-field .ant-select-selector input {
  height: 38px !important;
  box-shadow: none !important;
  line-height: 38px !important;
}

.select-family-field span.ant-select-selection-search {
  top: -2px !important;
}

.select-family-field span.ant-select-selection-placeholder {
  line-height: 35px !important;
}

.add-family-plus {
  position: absolute;
  z-index: 99;
  box-shadow: 0px 12px 24px rgb(68 68 68 10%);
  border-radius: 12px;
  background: #ffff;
  padding: 16px 20px;
  top: 50px;
  left: 0;
  width: 60%;
}

.total-credits-amount {
  background-color: rgba(235, 237, 241, 0.5);
  border-radius: 12px;
  padding: 20px 40px;
}

.ant-radio-input:focus + .ant-radio-inner,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.ant-picker-focused,
input,
textarea,
input:focus,
textarea:focus,
input:active,
textarea:active {
  box-shadow: none !important;
}

.ant-input-number-handler-wrap {
  display: none;
}

.preview-temp-btn.ant-btn:hover,
.preview-temp-btn.ant-btn:focus {
  border-color: #2ba7ca;
}

.price-range-slider .ant-slider-handle.ant-slider-handle-1 {
  transform: translateX(-24%) !important;
}

.cover-photo {
  height: 214px;
  object-fit: cover;
}

.ant-dropdown-menu-item:hover {
  background-color: #eaf6fa;
}

.ant-table-wrapper.schedule-table table tr td {
  padding-top: 20px;
  padding-bottom: 20px;
}

.tag-people-field .ant-select-selector {
  min-height: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.document-img-slider.addactivity-img .arrow-icon.arrow-next {
  top: 50%;
  margin: 0;
  right: 10px;
  transform: translateY(-50%);
}

.document-img-slider.addactivity-img .arrow-icon.arrow-prev {
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  left: 10px;
}

.add-group-modal .ant-modal-body {
  padding: 40px 56px;
}

.group-decrement {
  border-radius: 50px 0px 0px 50px;
}

.group-increment {
  border-radius: 0px 50px 50px 0px;
}

.group-decrement,
.group-increment {
  min-width: 70px;
  max-width: 70px;
  height: 68px;
  font-size: 50px;
  line-height: 68px;
  padding: 0;
  border: none;
  background-color: #ebedf1 !important;
  box-shadow: none !important;
}

h1.group-count {
  border: 1px solid #ebedf1;
  margin: 0 5px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 161px;
  width: 100%;
  font-weight: 600;
  font-size: 32px;
  line-height: 28px;
}

.default-phone-country-field {
  height: 41px;
}

.default-phone-country-field .ant-select-selector {
  padding: 0px 20px !important;
  border-radius: 50px !important;
}

.default-phone-country-field span.ant-select-selection-item,
.default-phone-country-field span.ant-select-selection-placeholder {
  color: #000000 !important;
  line-height: 41px !important;
}

.default-phone-country-field span.ant-select-arrow .anticon-down svg {
  fill: #000;
}

.permission-table table .ant-table-thead > tr > th,
.permission-table .ant-table-tbody > tr > td {
  background: transparent;
}

.frequently-asked-ques {
  padding: 50px 30px 20px 30px;
}

.tabpane-area {
  padding: 30px 30px;
}

li.ant-list-item.question-list {
  border: 1px solid #eeeff1 !important;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px 20px;
  position: relative;
}

li.ant-list-item.question-list ul.ant-list-item-action {
  align-self: flex-start;
  margin-left: 10px;
  width: 70px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 20px;
  top: 20px;
}

li.ant-list-item.question-list
  ul.ant-list-item-action
  .ant-list-item-action-split {
  display: none;
}

li.ant-list-item.question-list h4.ant-list-item-meta-title {
  padding-right: 100px;
}

.create-new-question {
  font-size: 16px;
}

@media screen and (max-width: 1359px) {
  .create-event-main-content-wapper {
    padding: 0 40px;
  }

  .event-step-cont-btn {
    left: 40px;
    right: 40px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .text-lg-left {
    text-align: left !important;
  }

  .mb-lg-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 1070px) {
  .weather-forecast-weekly-days-list {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .right-col-blue {
    display: none;
  }

  .pr {
    padding-right: 20px !important;
  }

  .page-header {
    padding-left: 20px;
  }

  .page-content {
    padding: 0 20px;
    margin-top: 20px;
  }

  .organizations-table-onselect-btn {
    justify-content: flex-start;
    padding-top: 10px;
  }

  .right-search-block {
    padding-top: 10px;
  }

  .right-search-block .table-action-btn.new-organizations-export-btn {
    justify-content: flex-start !important;
  }

  .right-search-block .btn-new {
    width: 154px !important;
  }

  .organizations_table table tr th,
  .organizations_table table tr td {
    white-space: nowrap;
  }

  .header-nav.ant-layout-header input.search-input-group {
    max-width: 250px;
  }

  .mb-md-15 {
    margin-bottom: 15px;
  }

  .profile-tabs .ant-tabs-tab {
    padding: 12px 12px !important;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 12px;
  }

  .header-nav.ant-layout-header {
    padding-right: 20px;
  }

  .sessions-data-table table tr th,
  .sessions-data-table table tr td {
    white-space: nowrap;
  }

  .data-title {
    font-size: 15px;
  }

  .mycalender.event-side-calender .ant-picker-content th,
  .ant-picker-content td {
    max-width: 15px;
    min-width: 15px;
  }

  .event-search-box {
    margin-left: 0 !important;
  }

  .register_child_button-events {
    margin-right: 10px;
  }

  .side-bar.create-event-side-bar {
    flex: 0 0 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    width: 300px !important;
  }

  .event-layout .ant-layout-sider-children {
    width: 300px !important;
  }

  .create-event-side-bar .ant-steps-item-description {
    font-size: 16px;
    line-height: 22px;
  }

  .schedule-and-activities-step-cale.ant-col-lg-11
    .ant-picker-calendar.ant-picker-calendar-mini {
    height: auto !important;
    margin-bottom: 40px;
  }

  .additional-setting-session-col {
    padding-left: 0px;
  }

  .page-header.main-height.setting-main-page {
    padding-right: 10px;
  }

  .setting-list li.ant-list-item {
    padding: 12px !important;
  }

  .setting-optn-tab {
    font-size: 16px;
  }
}

.document-img-slider {
  max-width: 339px;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  filter: drop-shadow(0px 1px 8px rgba(99, 103, 103, 0.2));
  position: relative;
}

.document-img-slider .ant-carousel .slick-dots li {
  width: 8px;
  height: 8px;
}

.document-img-slider .ant-carousel .slick-dots li button {
  width: 8px;
  height: 8px;
  background: #000;
  border-radius: 50%;
  opacity: 0.2;
}

.document-img-slider .ant-carousel .slick-dots li.slick-active {
  width: auto;
}

.document-img-slider .ant-carousel .slick-dots li.slick-active {
  width: auto;
}

.document-img-slider .ant-carousel .slick-dots li.slick-active button {
  background-color: #000;
  opacity: 1;
}

.document-img-slider .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}

.arrow-icon {
  background: #ffffff;
  border: 1px solid #ebedf1;
  box-shadow: 0px 12px 24px rgba(68, 68, 68, 10%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 23%;
  bottom: 0;
  margin: auto 0;
  z-index: 99;
  cursor: pointer;
}

.arrow-icon.arrow-next {
  right: -14px;
}

.arrow-icon.arrow-prev {
  left: -14px;
}

.img-frontside-view {
  background-color: #f7f8f9;
  border-radius: 20px 20px 0 0;
  position: relative;
  top: 32px;
  z-index: 99;
}

.status-additional-skill {
  background: #ff6961;
  border-radius: 100px;
  align-items: center;
  flex-direction: row;
  display: flex;
  color: #fff;
  height: 28px;
  width: 146px;
}

.mentor-details-basic-introduction {
  display: flex;
  align-items: center;
  width: 517px;
  height: 70px;
  background-color: #f5f6f8;
  padding: 15px 0px 15px 20px;
}

.blue-icon {
  position: relative;
}

.introduction-count {
  position: absolute;
  left: 73px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.introduction-rating {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #8cc73f;
}

.participate-text-icon {
  justify-content: space-between;
  display: flex;
}

.text-fiels-plus-icon {
  position: absolute;
  right: 11px;
  top: 10px;
}
.w-100 {
  width: 100%;
}
.img-item {
  height: 140px;
  width: 140px;
  position: relative;
}

.img-item .ant-image-mask {
  border-radius: 12px;
}
.image-style {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}
.time {
  color: #000 !important;
  margin-left: 12px;
  font-weight: 500;
  font-size: 10px;
  padding-top: 1px;
}
.item-center {
  justify-items: center;
}
.bordered {
  border: 1px solid #ebedf1;
  border-radius: 10px;
  padding: 8px;
}
.reqbar {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
.search-bar {
  width: 85%;
}
.search-bar .search-input-group {
  height: 43px !important;
}
.req-select .ant-select-selection-item {
  top: 5px;
  display: inline;
}
.req-select .ant-select-selection-item .sort {
  font-weight: 600;
  display: inline;
}
.req-select .anticon-search {
  top: 13px !important;
}
.activity-list {
  margin-top: 10px;
}
.ant-checkbox-inner {
  border-radius: 12px;
  width: 20px;
  height: 20px;
  border: 1.7px solid #ebedf1;
  border-radius: 4px;
  cursor: pointer;
}
.my-checkbox:checked {
  background-color: lightgreen !important;
}
.chips-area {
  margin-top: 10px;
}
.chip {
  background: #ebedf1;
  border-radius: 100px;
  padding: 4px 12px;
  margin-right: 10px;
}
.chip p {
  font-size: 10px;
  font-weight: 500;
}

.organization_pagination .ant-table-pagination.ant-pagination {
  margin-top: 30px !important;
}

.width-140 {
  width: 140px !important;
}
.alert_cancel_reg_button {
  width: 205px;
  height: 52px;
  border-radius: 8px;
  background-color: #ff6961;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}
.ant-steps-item-icon:after {
  font-size: 20px;
  width: 24px;
  height: 24px;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #000 !important;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #000 !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon:after {
  color: #fff;
  background-color: #a6ca16;
  border: 1px solid #a6ca16;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon:after {
  color: #ebedf1;
  background-color: #ebedf1;
}

.checkbox-style .ant-checkbox-inner {
  width: 24px !important;
  height: 24px !important;
  border: 1px solid #908f98 !important;
  border-radius: 4px !important;
}

.select-field .ant-select-selector {
  align-items: center;
}

.checkbox-container .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}
