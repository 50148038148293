.ant-picker-panel-container {
  width: 206px !important;
}

.select-activityModal {
  .activity-select-container {
    height: 450px;
  }
}

.default-activity {
  .btn {
    border: 1px solid #2ba7ca;
    border-radius: 12px;
    background-color: transparent;
    padding: 6px 24px;
    color: #2ba7ca;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    &.selected-activity {
      background-color: #2ba7ca;
      color: #fff;
    }
  }
}

.create-activity-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #2ba7ca;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  border: none;
}

.confirm-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 40px;
  border-radius: 12px;
  background-color: #2ba7ca;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}

.secondary-confirmation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 30px;
  border-radius: 12px;
  background-color: transparent;
  color: #2ba7ca;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #2ba7ca;
  cursor: pointer;
}

.day-modal-check {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2ba7ca !important;
    border: #2ba7ca !important;
  }
}
