@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&family=Poppins:wght@500&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 'Montserrat', sans-serif;
}

.event-card {
  display: flex;
  align-items: top;
  justify-content: space-between;
  padding: 17px 0;
  border-bottom: 1px solid #eeeff1;
}

.details-event-card {
  display: flex;
}

.org-event-img {
  /* width: 220px;
  height: 150px;
  background-color: grey;
  border-radius: 8px; */
  width: 92px;
  height: 62px;
  padding: 8px 0px 0px 0px;
  gap: 0px;
  border-radius: 8px;
  background: #ebedf1;
  /* padding: 8px; */
}
/* .arrowDate {
  width: 15px;
  height: 15px;
  margin-top: 25px;
  padding: 1px;
  margin-right: 3px;
} */
.dateMonth {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: center;
}
.org-event-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.data {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-left: 12px;
}

.data h3 {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-top: 4px;
}

.location {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.location img {
  width: 16px;
  height: 16px;
}

.location span {
  font-size: 14px;
  font-weight: 500;
  color: #939598;
  margin-left: 8px;
}

.event-detail {
  display: flex;
  margin-top: 14px;
}

.event-detail .img {
  /* border: 2px solid #eeeff1; */
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin-top: 2px;
}

.event-detail .img img {
  width: 30px;
  height: 30px;
}

.view-more {
  color: #2ba7ca;
  font-size: 13px;
  font-weight: 500;
  margin-top: 18px;
}

.view-more button {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.button .event-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  padding: 9px 40px;
  background-color: transparent;
  border: 1px solid #2ba7ca;
  border-radius: 8px;
  color: #2ba7ca;
  font-weight: 500;
}

.color-black {
  color: #000;
  font-size: 2rem;
}

.weight-500 {
  font-weight: 500;
}
