.img-preview {
  height: 100px;
  width: 100px;
  position: relative;

  .closing-icon {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 2px;
    width: 30px;
    cursor: pointer;
  }
}

// custom modal
.custom-common-modal {
  .modal-body {
    .modal-content {
      height: auto;
      max-height: 450px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;

      &::-webkit-scrollbar {
        padding-left: 2rem;
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #2ba7ca;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        background: #ebedf1;
      }

      /* For Firefox */
      // scrollbar-width: thin;
      // scrollbar-color: #2ba7ca;
    }
  }
  .ant-modal-footer {
    border: none;
    border-radius: 0;
  }
}

.custom-modal-footer {
  .modal-footer-btn {
    padding: 12px 24px 12px 24px;
    height: auto;
    border: none;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    cursor: pointer;

    &.primary {
      background-color: #2ba7ca;
      border: 1px solid #2ba7ca;
      color: #fff;
      min-width: 150px;
      &:hover {
        background-color: transparent;
        color: #2ba7ca !important;
      }
    }

    &.danger {
      background-color: #ff6961;
      border: 1px solid #ff6961;
      color: #fff;
      min-width: 150px;
      &:hover {
        background-color: transparent;
        color: #ff6961 !important;
      }
    }

    &.secondary {
      background-color: #eeeff1;
      color: #000;
      border: 1px solid #eeeff1;
      &:hover {
        background-color: transparent !important;
        border-color: #000;
      }
    }
  }
}

// chat styles
.message-box-width {
  width: auto;
  max-width: 320px;
  &.background-send {
    background-color: #eaf6fa;
  }

  &.background-received {
    background-color: #fdf0e4;
  }
}
.bg-colored {
  background-color: #eaf7fb;
}
.input-box {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.send {
  border: none;
  outline: none;
  background-color: transparent;
}

.custom-file-upload {
  display: none;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 80%;
  height: 180px;
  border-radius: 12px;
}

// modal preview
.image-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.image-preview-container {
  max-width: 80%;
  height: 100%;
  max-height: 768px;
  margin: auto;
}
