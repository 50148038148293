.emailTxt {
  //styleName: H6;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 14.63px;
  text-align: left;
  color: #000000;
}
.ChangeText {
  //styleName: H2;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #000000;
}
.currentEmail {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #939598;
}
.email {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #939598;
}
