.propose-table .ant-table-thead {
  display: none !important;
}
.job-req {
  .ant-radio-wrapper {
    margin-top: 15px;
  }
}
.category-checkbox {
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}

.rejectRequestModal {
  width: 30% !important;
  transform: translate(0, -50%) !important;
  top: 49%;
  .ant-modal-body {
    padding: 0px 40px;
  }
  .ant-modal-footer {
    display: none;
  }
}

.reject-mentor-field{
  height: 100px !important;
  
}