.form-input-style {
  padding: 10px 16px;
  border: 1px solid #ebedf1;
  border-radius: 10px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    border-radius: 4px;
    background-color: #2ba7ca;
    border: none;
    outline: none;
    color: #fff;
  }
}

//calendar days selection
.event-days-calendar {
  .react-calendar {
    width: 100% !important;
    border: none !important;
  }
  .react-calendar__tile--now {
    background-color: transparent !important;
    color: #000 !important;
  }
  .react-calendar__tile--active:enabled {
    background-color: transparent !important;
    color: #000 !important;
  }
  .react-calendar__tile--hasActive {
    background-color: #3274b6 !important;
    color: #fff;
  }
  .selected {
    background-color: #3274b6 !important;
    color: #ebedf1 !important;
    border-radius: 50% !important;
    border: 0.5rem solid #fff !important;
  }
  .react-calendar__tile--active.selected {
    background-color: #3274b6 !important;
    color: #ebedf1 !important;
    border-radius: 50% !important;
  }
  .react-calendar__tile abbr {
    font-size: 15px !important;
    line-height: 22px !important;
  }
}

// border
.border-bottom {
  border-radius: 0 0 8px 8px;
}
.border-top {
  border-radius: 8px 8px 0 0;
}

// category custom desgn
.custom-category-checkbox {
  display: flex;
  align-items: center;
  .ant-checkbox-inner {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #ebedf1;
  }
  .ant-checkbox-inner::after {
    left: 32.5%;
  }
}
