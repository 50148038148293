.statistic-card {
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  margin: 10px;
  text-align: start;
  // border: solid 2px;
  width: 165px; /* Set desired width */
  height: 165px;

  // box-shadow: green;
}
.statistic-card.shadow {
  box-shadow: 0px 0px 4px 0px #1b26330d;

  box-shadow: 0px 0px 6px 0px #1b26331a;
}

.statistic-card h3 {
  width: 24px;
  height: 24px;
  top: 16px;
  left: 20px;
  gap: 0px;
  opacity: 0.5px;
}

.statistic-card .number {
  width: 90px;
  height: 47px;
  margin-top: 20px;
  left: 20px;
  gap: 0px;
 
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 600;
  line-height: 47.2px;
  letter-spacing: 0.5px;
  text-align: left;

  font-weight: bold;
}

.statistic-card .description {
  width: 109px;
  height: 16px;
  margin-top: 20px;
  // margin-left: 20px;
  gap: 0px;

  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;

  // font-size: 14px;
  // color: #aaa;
}
