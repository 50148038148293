.additional-setting-container {
  width: 100%;
  max-width: 850px;
  margin: auto;

  .content-container {
    height: calc(100vh - 300px);

    .inner-cont {
      width: 50%;
    }
  }
}

// faq styling
.faq-container {
  .ant-checkbox-inner {
    width: 26px;
    height: 26px;
    border: 1px solid #908f98;

    &::after {
      top: 47%;
      width: 7.714286px;
      height: 15.142857px;
    }
  }

  .question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    cursor: pointer;

    .question-carret {
      transform: rotate(0deg);
      transition: transform 0.2s ease-in;
    }
  }

  .answer {
    display: hidden;
    visibility: none;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease-in;
  }

  &.active {
    .question {
      .question-carret {
        transform: rotate(180deg);
      }
    }
    .answer {
      padding: 10px 0;
      display: block;
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }
}

// security radio button
.security {
  .ant-radio-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}
