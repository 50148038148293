.basic_newsContainer {
  border: 1px solid #eeeff1;
  margin-top: 16px;
  width: 100%;
  max-width: 600px;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  .gray_text {
    color: #908f98;
    font-size: 12px;
    font-weight: 400;
  }

  .like_count {
    margin: 10px -11px;
  }

  .like_share_container {
    border-top: 1px solid #eeeff1;
    padding: 16px 0 0 0;
  }

  .img_cont {
    position: relative;
  }

  .main_image_contianer {
    margin-top: 20px;

    &.two_column_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    &.three_column_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      .img_container:nth-child(1) {
        grid-row: 1 / span 2;
      }
    }

    &.four_column_grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      .img_container:nth-child(1) {
        grid-column: 1 / span 3;
      }
    }

    &.five_column_upper_container {
      display: flex;
      gap: 16px;
    }

    &.five_column_lower_container {
      display: flex;
      gap: 16px;
    }

    .img_container {
      width: 100%;
      /* // height: 500px; */
    }
  }

  .additional_image_indicator {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    width: 175px;
    height: 183px;
    position: absolute;
    bottom: 0;
    right: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  .additional_image_indicator_unauth {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    width: 175px;
    height: 183px;
    position: absolute;
    bottom: 0rem;
    right: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  .more_than_five_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
}

.clr_btn {
  background-color: transparent;
  border: none;
  font-weight: 500;
  color: rgba(147, 149, 152, 1);
  cursor: pointer;
}
.apply_btn {
  background-color: #f7941d;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
}

.dropdwn {
  padding: 10px 0px;
}
.dropdwn label {
  margin-bottom: 20px;
  margin-right: 0;
  margin-left: 0 !important;

  .ant-checkbox {
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
    }
  }
}
.dropdwn span {
  font-weight: 500;
  font-size: 14px;
}
.dropdwn .ant-checkbox-wrapper:hover {
  background-color: rgba(43, 167, 202, 0.15) !important;
  padding: 10px;
  border-radius: 8px;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  background: transparent !important;
}

.search_field {
  border-bottom: 1px solid #ebedf1;
  .full_width_Input {
    border: none !important;
  }
}
.organizations_filter {
  height: 150px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 12px;
    background-color: rgb(245, 245, 245);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f7941d;
    border-radius: 12px;
  }
}

/* //preview img */
.image_preview_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.image_preview_container {
  max-width: 80%;
  height: 100%;
  max-height: 768px;
  margin: auto;
  position: relative;
}

.cross_icon {
  position: absolute;
  top: 0;
  right: -18px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.arrows {
  position: absolute;
  top: 50%;
  z-index: 999;
  cursor: pointer;

  &.arrow_left {
    left: 20%;
  }
  &.arrow_right {
    right: 20%;
  }
}
.action-buttons {
  padding: 8px 24px 8px 24px;
  gap: 16px;
  border: 1px 0px 0px 0px;
  border-top: 1px solid #efeff2;
  &:hover {
    background-color: #fff; // Underline on hover
  }
}

.unpublish-button,
.share-button {
  font-weight: bold;
  padding: 0;
  border: none;
  background: none;
}
.Note-text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
.reason-text {
  //styleName: H6;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
  text-align: left;
}
