.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-editor {
  height: 100% !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Roboto']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Roboto']::before {
  font-family: 'Roboto', cursive;
  content: 'Roboto' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Raleway']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Raleway']::before {
  font-family: 'Raleway', cursive;
  content: 'Raleway' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Montserrat']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Montserrat']::before {
  font-family: 'Montserrat', cursive;
  content: 'Montserrat' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Lato']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Lato']::before {
  font-family: 'Lato', cursive;
  content: 'Lato' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Rubik']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Rubik']::before {
  font-family: 'Rubik', cursive;
  content: 'Rubik' !important;
}

/* Set content font-families */
.ql-font-Roboto {
  font-family: 'Roboto';
}
.ql-font-Raleway {
  font-family: 'Raleway';
}
.ql-font-Montserrat {
  font-family: 'Montserrat';
}
.ql-font-Lato {
  font-family: 'Lato';
}
.ql-font-Rubik {
  font-family: 'Rubik';
}
.ql-font-Poppins {
  font-family: 'Poppins';
}

// font size
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: 'Normal';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: 'Large';
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: 'Huge';
  font-size: 18px !important;
}

// custom styleing
.ql-toolbar.ql-snow {
  border: 1px solid #ebedf1;
  border-radius: 12px 12px 0 0;
}
.ql-container.ql-snow {
  border: 1px solid #ebedf1;
  border-radius: 0 0 12px 12px;
  height: 300px;
}

.editor-input .ql-editor span {
  display: inline !important;
}

.ql-editor img {
  width: 150px;
  height: 150px;
  display: block;
}

// values slider
.main-wrappper-values {
  overflow: auto;

  .custom-value-param {
    background-color: #ebedf1;
    padding: 4px 8px;
    border-radius: 5px;
    width: fit-content;
    display: inline-block;

    p {
      white-space: nowrap;
    }
  }
}
