.step3-main-container {
  width: 60%;
  margin: auto;
  padding: 30px;

  .content {
    height: calc(100vh - 320px);
  }

  .fees-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #eeeff1;
    border: none;
    outline: none;
    height: 52px;
    border-radius: 8px;
    cursor: pointer;
  }

  .total-amount-container {
    border: 1px solid #8cc73f;
    border-radius: 13px;
  }

  @media screen and (max-width: 1920px) {
    width: 80%;
  }

  @media screen and (max-width: 1680px) {
    width: 90%;
  }

  @media screen and (max-width: 1500px) {
    width: 100%;
  }

  @media screen and (max-width: 920px) {
    padding: 60px 30px 30px 30px;
  }
}

.custom-slider {
  .ant-slider-dot {
    width: 14px;
    height: 14px;
    border: 1px solid rgba(156, 165, 176, 1);
    margin-top: -3px;
  }
  .ant-slider-mark {
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    color: #8e93a3;
  }
}
